import React from 'react';
import { Box, IconButton } from '@mui/material';
import '../Running.css';
import { Filter, FilterPrefs, FilterDrawer } from './Filter';
import { TBar, ABar } from './Widgets';
import { addCell, formatTime, formatShortName, getRunner, serverRequest } from './Util';
import { GlobalContext } from '../context/GlobalContextProvider';
import MenuIcon from '@mui/icons-material/Menu';

interface ParkrunTableState {
    course: string;
    year: number;
}

interface ParkrunTableProps {
    records: {};
    age_group: string;
    gender: string;
    mainOnly: boolean;
}

export class ParkrunTable extends React.Component<ParkrunTableProps, ParkrunTableState> {
    constructor(props) {
        super(props);
        this.state = { course: '', year: 0 }
        this.onSelectRace = this.onSelectRace.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.records !== this.props.records)
            this.setState( { course: '', year: 0} );
    }
    
    onSelectRace = (e) => {
        const course = e.currentTarget.getAttribute('course-key');
        const year = Number(e.currentTarget.getAttribute('year-key'));

        const deselect = this.state.course === course && this.state.year === year;
        if (deselect)
            this.setState( { course: '', year: 0} );
        else
            this.setState( { course: course, year: year} );
    }

    render_event(cells, course, year, elem, eventrec) {
        if (elem !== undefined) {
            var perf = elem[0]
            var runner = getRunner(elem[1]);

            var sbstyle = (perf === eventrec) ? {'color': 'red'} : {};
            if (course === this.state.course && year === this.state.year) {
                sbstyle['backgroundColor'] = 'yellow';
            }

            cells.push(<td onClick={this.onSelectRace} course-key={course} year-key={year} style={sbstyle} className='pbcol'>
                            <b>{formatTime(perf, course)}</b><br/>{formatShortName(runner)}
                            </td>);
        } else
            cells.push(addCell(''));
    }

    static sortByCourse(a, b) {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    }
    
    render() {
        // Finds all courses and all years and builds a direct access dict
        var pbdict: {} = {};
        var courserec: {} = {};
        var years: string[] = [];
        var totalfin: {} = {}

        for (var key in this.props.records) {
            var pb = this.props.records[key]
            var year = pb['Year']
            var course = pb['Venue']
            var numfin = pb['Num']

            // Calculates all-time records
            if (courserec[course] === undefined || courserec[course][0] > pb['Time'])
                courserec[course] = [pb['Time'], pb['ID']]

            if (totalfin[course] === undefined)
                totalfin[course] = 0
            totalfin[course] += numfin

            if (years.indexOf(year) < 0)
                years.push(year)

            pbdict[year+","+course] = [pb['Time'], pb['ID']]
        }

        // Sorts events and years
        var courses: string[] = Object.keys(courserec);
        courses.sort(ParkrunTable.sortByCourse);
        years.sort().reverse();

        var minfin = (this.props.age_group !== "") ? 10 : 100;
        // console.log("Age group: " + "'" + this.props.age_group + "' => " + minfin)
        var rows: JSX.Element[] = [];
        for (course of courses) {
            if (this.props.mainOnly && totalfin[course] < minfin)
                continue;

            var cells: JSX.Element[] = [];     
            var bgcol = 'var(--table-header-color)';
            var title = course + " [" + totalfin[course] + "]"
            cells.push(<th className='sticky-col first-large-col' style={{backgroundColor: bgcol}}>{title}</th>);
            // All-time Records
            var elem = courserec[course]
            this.render_event(cells, course, 0, elem, elem[0])

            for (year of years) {
                elem = pbdict[year+","+course]
                this.render_event(cells, course, year, elem, courserec[course][0]);
            }
            rows.push(<tr>{cells}</tr>);
        }

        // Shows selected race or leaderboard (TBD)
        var selDetail = <></>
        var maxH = '100vh';

        // Header
        var head = <tr><th></th><th>{"ALL"}</th>{years.map((key, index) => { return <th>{key}</th> })}</tr>
        var table = <div className='wrapper' style={{'maxHeight': maxH}}>
                        <table className='bicolortable'><thead>{head}</thead><tbody className='link'>{rows}</tbody></table>
                    </div>
        
        return <div style={{padding: '16px'}}>{table}{selDetail}</div>
    }
}

interface ParkrunProps { }

interface ParkrunState {
    course: string;
    selRaceID: number;
    selRunnerID: string;
    records: {};
    age_group: string,
    gender: string,
    mainOnly: boolean
}

export class Parkrun extends  React.Component<ParkrunProps, ParkrunState> {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            course: '',
            records: {},
            selRaceID: 0,
            selRunnerID: '',
            age_group: '',
            gender: '',
            mainOnly: true
        }
        this.loadParkrun = this.loadParkrun.bind(this);
        this.onSelectRace = this.onSelectRace.bind(this);
    }

    componentDidMount() {
        this.loadParkrun(new Filter(0, 0, '', 'W', '', true, ''), true);
    }

    onSelectRace = (e) => {
        const ID = Number(e.currentTarget.getAttribute('data-key'));
        const rID = e.currentTarget.getAttribute('runner-key');
        this.setState( { selRaceID: ID === this.state.selRaceID ? 0 : ID, selRunnerID: rID === this.state.selRunnerID ? 0 : rID } );
    }
    
    loadParkrun(filter: Filter, requery) {
        if (requery) {
            serverRequest("parkrunrecords?gender=" + filter.gender + "&age_group=" + filter.age_group)
            .then(res => {
                this.setState({records: res, selRaceID: 0, course: '', gender: filter.gender, age_group: filter.age_group, mainOnly: filter.mainOnly});
            });
        } else {
            this.setState({mainOnly: filter.mainOnly});
        }
    }

    static filter_prefs: FilterPrefs = {year: false, month: false,
        event: false, all_event: false, mainOnly: true,
        gender: true, all_gender: false, age_group: true, all_age_group: false, meeting: false };

    openMenu(context) {
        context.setMenuOpen(!context.menuOpen);
    }

    render() {
        var filter = <FilterDrawer onChange={this.loadParkrun} prefs={Parkrun.filter_prefs} start_filter={new Filter(0, 0, '', 'W', '', this.state.mainOnly, '')} display_filter={true} />  
        
        var appbar = <div>
                <ABar position="top" >
                    <TBar>
                    <IconButton sx={{ color: 'var(--paper-color)'}} onClick={() => this.openMenu(this.context)}>
                        <MenuIcon fontSize="large"></MenuIcon>
                    </IconButton>
                    <div className='largetext' >Parkrun Records</div>
                    <Box sx={{ flexGrow: 1}} />
                    {filter}
                    </TBar>
                </ABar>
            </div>

        var tables = <ParkrunTable records={this.state.records} age_group={this.state.age_group} gender={this.state.gender} mainOnly={this.state.mainOnly} />

        return <div>
            {appbar}
            <div>{tables}</div>
            </div>;
    }
}
