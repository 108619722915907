import React from 'react';
import { Box, IconButton, ToggleButtonGroup, ToggleButton, FormControlLabel, Checkbox } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import EmojiEvents from '@mui/icons-material/EmojiEvents';
import HistoryIcon from '@mui/icons-material/History';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CalculateIcon from '@mui/icons-material/Calculate';

import { Filter, FilterPrefs, FilterDrawer } from './Filter';
import { TBar, ABar } from './Widgets';
import { RunnerCharts } from './Charts';
import { RunnerAgeGrade } from './AgeGrading'

import { getRunner, Runner, Finisher, Runners_dict, Runners_array, equalObjects, sortKeys, serverRequest } from './Util';
import { getFinisherCard } from './Races';
import { PBTable } from './Records';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import MenuIcon from '@mui/icons-material/Menu';
import { GlobalContext } from '../context/GlobalContextProvider';

import './Runner.css';

interface AllRunnersProps { 
    onSelectRunner;
}

interface AllRunnersState {
    selLetter: string;
}

class AllRunners extends React.Component<AllRunnersProps, AllRunnersState> {
    constructor(props) {
        super(props);
        this.state = { selLetter: '' }
        this.onClick = this.onClick.bind(this);
        this.onSelectRunner = this.onSelectRunner.bind(this);
    }

    onClick(e) {
        const ID: string = e.currentTarget.getAttribute('data-key');
        this.setState( { selLetter: ID });
    }

    onSelectRunner(e) {
        const rID: string = e.currentTarget.getAttribute('data-key');
        this.props.onSelectRunner(rID);
    }

    render() {
        var letters: JSX.Element[] = [];

        var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        for (var n=0; n<chars.length; n++) {
            var lett = chars[n];
            letters.push(<span key={lett} onClick={this.onClick} data-key={lett} className='link'
                            style={{'margin': '5px', 'border': '1px solid', 'width': '2.5em', 'height': '2em', 'display': 'inline-block',
                            'backgroundColor': 'var(--primary-color)', 'color': 'white', 'textAlign': 'center', 'lineHeight': '2em' }}>{lett}</span>);
            if ((n+1) % 6 === 0)
                letters.push(<br key={n}/>);
        }
    
        var table = <div></div>
        if (this.state.selLetter !== '') {
            var keys = Object.keys(Runners_dict);
            var filterkeys = keys.filter(key => getRunner(key).First.startsWith(this.state.selLetter));
            var sortedkeys = filterkeys.sort(function compareFn(a, b) {
                var ra = getRunner(a);
                var rb = getRunner(b);
                var ran = ra.First + " " + ra.Last;
                var rbn = rb.First + " " + rb.Last;
                return (ran < rbn) ? -1 : 1;
            });

            var initials = '';
            var currlen = this.state.selLetter.length;
            if (currlen < 3) {
                letters.push(<br key={100}/>)
                for (var key in sortedkeys) {
                    var rkey = sortedkeys[key];
                    var runner: Runner = getRunner(rkey);

                    var rinit = runner.First.substring(0, currlen+1);
                    if (rinit !== initials) {
                        letters.push(<span key={rinit} onClick={this.onClick} data-key={rinit} className='link'
                            style={{'margin': '5px', 'border': '1px solid', 'width': '2.5em', 'height': '2em', 'display': 'inline-block',
                            'backgroundColor': 'var(--secondary-color)', 'color': 'white', 'textAlign': 'center', 'lineHeight': '2em' }}>{rinit}</span>);
                        initials = rinit;
                    }
                }   
            }

            var rows: JSX.Element[] = [];
            for (key in sortedkeys) {
                rkey = sortedkeys[key];
                runner = getRunner(rkey);
                rows.push(<div className='griditem' key={rkey} onClick={this.onSelectRunner} data-key={rkey}>
                            <b>{runner.First + " " + runner.Last}</b>{" " + runner.Gender + " " + runner.AG}
                        </div>);
            }
            if (rows.length > 0) {
                table = <div className='gridcontainer'>{rows}</div>
            }
        }
        return <div className={'dr-runners-container'}>{letters}{table}</div>
    }
}



// Sorts by all-time Records (PBs), then by Season records (SB) then by date descending
function sortByAchievement(results) {
    var keys: string[] = Object.keys(results);
    var sortedkeys: string[] = keys.sort(function compareFn(a, b) {
        var res1: Finisher = results[a];
        var res2: Finisher = results[b];

        if (res1.Rec === res2.Rec) {
            if (res1.YRec === res2.YRec) {
                return res1.Date > res2.Date ? -1 : 1;
            } else {
                if (res1.YRec >= 1 && res2.YRec >= 1)
                    return res1.YRec < res2.YRec ? -1 : 1;
                else if (res1.YRec >= 1)
                    return -1;
                else
                    return 1;
            }
        } else {
            if (res1.Rec >= 1 && res2.Rec >= 1)
                return res1.Rec < res2.Rec ? -1 : 1;
            else if (res1.Rec >= 1)
                return -1;
            else
                return 1;
        }
    });

    return sortedkeys;
}

interface FinishersProps {
    finishers: {};
    variant: string;
}

interface FinishersState {
    selRaceID: number;
}

export class Finishers extends React.Component<FinishersProps, FinishersState> {
    constructor(props) {
        super(props);
        this.state = { selRaceID: 0 };
        this.onSelectRace = this.onSelectRace.bind(this);
    }

    onSelectRace = (e) => {
        const ID = Number(e.currentTarget.getAttribute('data-key'));
        this.setState( { selRaceID: this.state.selRaceID === ID ? 0 : ID } );
    }

    render() {
        const finishers: {} = this.props.finishers;
        var keys;
        if (this.props.variant === "name")
            keys = sortByAchievement(finishers);
        else
            keys = sortKeys(finishers, 'Date', null, false);
        var cards: JSX.Element[] = [];
        var lastyear = 0;

        for (var key in keys) {
            var fkey = keys[key]
            var fin: Finisher = finishers[fkey];

            var thisyear = new Date(fin.Date).getFullYear();
            if (lastyear !== thisyear) {
                cards.push(<div key={thisyear} className='largetext' style={{margin: '5px', backgroundColor: 'var(--table-border-color)'}}>{thisyear}</div>);
                lastyear = thisyear;
            }

            cards.push(getFinisherCard(key, fin, fin.MeetingID === this.state.selRaceID, this.onSelectRace, this.props.variant))
        }

        return <div className='cardset'>{cards}</div>;
    }
}

interface RunnerPageState {
    loading: boolean;
    pbs: {};
    results: {};
}

interface RunnerPageProps {
    selID: string;
    mode: number;
    filter: Filter;
    achievements: boolean;
}

export class RunnerPage extends React.Component<RunnerPageProps, RunnerPageState> {
    constructor(props) {
        super(props);
        this.state = { pbs: {}, results: {}, loading: false}
        this.loadResults = this.loadResults.bind(this);
    }

    componentDidMount() {
        this.loadProfile();
        this.loadResults(this.props.filter);
    }

    componentDidUpdate(prevProps: RunnerPageProps) {
        if (!equalObjects(prevProps.filter, this.props.filter) || prevProps.selID !== this.props.selID || prevProps.achievements !== this.props.achievements) {
            this.loadResults(this.props.filter);
        }
    }
    
    loadProfile() {
        serverRequest("profile?ID=" + this.props.selID)
        .then(res => {
            this.setState({pbs: res});
        });
    }

    loadResults(filter: Filter) {
        if (!this.state.loading) {
            this.setState({loading: true});

            var filterstr = "ID=" + this.props.selID + "&meeting=" + filter.meeting + "&year=" + filter.year + "&month=" + filter.month + "&event=" + filter.event + "&achievements=" + (this.props.achievements ? "1" : "0");
            serverRequest("race?" + filterstr)
            .then(res => {
                this.setState({results: res, loading: false});
            });
        }
    }

    render() {
        if (this.props.selID !== '') {
            var page;
            switch (this.props.mode) {
            case 0:
                page = <PBTable pblist={this.state.pbs} showRunner={false} selRunnerID={this.props.selID} mainOnly={false} age_group='' gender='' />;
                break;
            case 1:
                page = <Finishers finishers={this.state.results} variant="normal" />
                break;
            case 2:
                page = <RunnerCharts results={this.state.results} />;
                break;
            case 3:
                page = <RunnerAgeGrade rID={this.props.selID} />;
                break;
            }

            return <Box sx={{ width: '100%' }}>
                <div className="card">
                    {page}
                </div>
                </Box>
        }

        return <></>;
    }
}

function RunnerProfile(props) {
    const { selID } = props;

    if (selID !== '') {
        var runner: Runner = getRunner(selID);
        var membership = '';

        switch (runner.Status) {
            case 'C':
                membership = "DR member since " + runner.StartYear;
                // membership = "DR member from " + runner.StartYear + " to " + runner.EndYear;
                break;
            case 'S':
                membership = "DR 2nd claim member from " + runner.StartYear + " to " + runner.EndYear;
                break;
            case 'O':
            case '?':
                membership = "DR member from " + runner.StartYear + " to " + runner.EndYear;
                break;
            case 'E':
            default:
                break;
            }

        var link = selID.startsWith("DR") ? <></> :
            <a href={"https://www.thepowerof10.info/athletes/profile.aspx?athleteid=" + selID} target="_blank" rel="noreferrer">
                Po10: {selID}
            </a>

        return <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div style={{flex: '1 1 100%'}}>{runner.Gender + " | " + runner.AG + " | " + runner.Club}</div>
                    <div style={{flex: '1 1 100%'}}>{membership}</div>
                    <div style={{flex: '1 1 100%'}}>{link}</div>
                </div>
    }

    return <></>;
}

interface AchievementsState {
    results: {};
}

interface AchievementsProps {
    filter: string;
}

export class Achievements extends React.Component<AchievementsProps, AchievementsState> {
    constructor(props) {
        super(props);
        this.state = { results: {}}
        this.loadRaces = this.loadRaces.bind(this);
    }

    componentDidMount() {
        this.loadRaces();
    }

    loadRaces() {
        serverRequest("race?" + this.props.filter + "&achievements=1")
        .then(res => {
            this.setState({results: res});
        });
    }

    render() {
        var page = <Finishers finishers={this.state.results} variant="name" />
        return <div className="card">
                {page}
            </div>
    }
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#0051AD', 0.15),
    '&:hover': {
      backgroundColor: alpha('#0051AD', 0.25),
    },
    marginLeft: 0,
    width: '60%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

interface RunnerSearchProps { 
    onSelectRunner;
}

interface RunnerSearchState {
    name: string;
    options: string[];
    selRunner: string | null;
    completekey: number;
}

export class RunnerSearch extends  React.Component<RunnerSearchProps, RunnerSearchState> {
    constructor(props) {
        super(props);
        this.state = { name: '', options: [], selRunner: null, completekey: 0 }
        this.onInputChange = this.onInputChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectRunner = (e) => {
        const ID = e.currentTarget.getAttribute('data-key');
        this.props.onSelectRunner(ID);
    }

    onInputChange(e, nameFilter: string) {
        var options: string[] = []
        if (nameFilter.length >= 2) {
            var tot = 0;
            for (var n=0; n<Runners_array.length && tot < 20; n++) {
                var runner: Runner = Runners_array[n];
                var matchName = runner.Last.toLowerCase().includes(nameFilter.toLowerCase()) ||
                                runner.First.toLowerCase().includes(nameFilter.toLowerCase()) ||
                                (runner.First + " " + runner.Last).toLowerCase().includes(nameFilter.toLowerCase());
                if (matchName) {
                    options.push(runner.ID);
                    tot += 1;
                }
            }
        }
        this.setState({name: nameFilter, options: options});
    }

    onSelectChange(e, rID: string | null) {
        if (rID != null) {
            this.setState( { selRunner: null, name: '', completekey: this.state.completekey+1 } );
            this.props.onSelectRunner(rID);
        }
    }

    render() {
        var ac_style = { // WTF!!!
            padding: '8px 0px 8px 36px',
            display: 'flex',
            minWidth: '20ch',
            maxWidth: '40vw',
            input : {
                color: 'var(--light-text-color)',
                fontSize: '1.6rem',
            },
        }
        var autocomp = <Autocomplete
            key={this.state.completekey}
            sx={ac_style}
            options={this.state.options}
            autoHighlight
            autoComplete={false}
            filterOptions={(x) => x}
            value={this.state.selRunner}
            onChange={this.onSelectChange}
            onInputChange={this.onInputChange}

            getOptionLabel={(rID: string) => {
                var runner: Runner = Runners_dict[rID];
                if (runner !== undefined) {
                    return runner.First + " " + runner.Last + " (" + runner.Gender + " " + runner.AG + ")";
                }
                return '';
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='standard'
                    placeholder="Runner name"
                    inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
 
        var search = <Search>
                    <div style={{padding: '0px 8px', height: '100%', position: 'absolute', pointerEvents: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <SearchIcon />
                    </div>
                    {autocomp}
                    </Search>
        
        return search;
    }
}

interface RunnerSelectorProps { }

interface RunnerSelectorState {
    name: string;
    selRunnerID: string;
    mode: number;
    filter: Filter;
    pbs: boolean;
}

export class RunnerSelector extends  React.Component<RunnerSelectorProps, RunnerSelectorState> {

  static contextType = GlobalContext;

  
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            selRunnerID: '',
            mode: 0,
            filter: new Filter(),
            pbs: false,
        }

        this.handleMode = this.handleMode.bind(this);
        this.handlePBS = this.handlePBS.bind(this);
        this.onSelectRunner = this.onSelectRunner.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.handleName = this.handleName.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    static filter_prefs: FilterPrefs = {year: true, month: true,
                    event: true, all_event: true, mainOnly: false,
                    gender: false, all_gender: true, meeting: true, age_group: false, all_age_group: true };

    onSelectRunner(rID) {
        this.setState( { selRunnerID: rID } );
    }

    handleMode(e, newValue) {
        if (newValue != null)
            this.setState( { mode: newValue });
    } 

     

    handlePBS(e) {
        var pbs = this.state.pbs;
        this.setState({pbs: !pbs});
    }

    handleName(e) {
        this.setState({name: e.target.value});
    }

    onBack(e) {
        this.setState({ selRunnerID: ''});
    }

    onFilter(filter, requery) {
        // Selecting a filter in Records mode will switch to Races mode
        var newMode = (this.state.mode === 0 && (filter.event !== '' || filter.meeting !== '' || filter.year > 0)) ? 1 : this.state.mode;
        this.setState( { filter: Object.assign({}, filter), mode: newMode });
    }

    openMenu(context) {
     
      context.setMenuOpen(!context.menuOpen);
  
    }

    render() {

        var leftmenu;
        var menu; 

        if (this.state.selRunnerID !== '') {
            var runner: Runner = getRunner(this.state.selRunnerID);
            var rname = '';
            if (runner !== undefined)
                rname = runner.First + " " + runner.Last;

            leftmenu = <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <div>
                       
                            <IconButton
                                onClick={this.onBack}
                                color="inherit"
                                sx={{verticalAlign: 'initial'}}
                                >
                                <ArrowBackIosIcon fontSize="large" />
                            </IconButton>
                            </div>
                            <div className='largetext'>{rname}</div>
                        </div>

            var filter = <FilterDrawer onChange={this.onFilter} prefs={RunnerSelector.filter_prefs} start_filter={new Filter(0, 0, '', '', '', false, '')} display_filter={true} />
            menu = filter
        } else {
          leftmenu =  <><IconButton sx={{ color: 'var(--paper-color)'}} onClick={() => this.openMenu(this.context)}>

            <MenuIcon fontSize="large"></MenuIcon>
                        </IconButton>
                        <div className='largetext' >
                        Runners
                    </div></>

            menu = <RunnerSearch onSelectRunner={this.onSelectRunner} />
        }
        
        var appbar = <div>
                <ABar position="top" >
                <TBar>

                    {leftmenu}
                    <Box sx={{ flexGrow: 1}} />
                    {menu}
                </TBar>
                </ABar>
                </div>

        if (this.state.selRunnerID !== '') {
            var style = {
                "& .MuiToggleButtonGroup-grouped": { color: "#aaa", padding: '10px' },
                "& .Mui-selected, .Mui-selected > svg": { color: "var(--menu-selected)" }
            }

            var options = <ToggleButtonGroup
                                    orientation="horizontal"
                                    size="large"
                                    exclusive
                                    sx = {style}
                                    value={this.state.mode}
                                    onChange={this.handleMode}>
                                <ToggleButton aria-label="Records" value={0}><EmojiEvents fontSize="large" /></ToggleButton>
                                <ToggleButton aria-label="Races" value={1}><HistoryIcon fontSize="large" /></ToggleButton>
                                <ToggleButton aria-label="Analytics" value={2}><BarChartOutlinedIcon fontSize="large" /></ToggleButton>
                                <ToggleButton aria-label="AgeGradingCalc" value={3}><CalculateIcon fontSize="large" /></ToggleButton>
                            </ToggleButtonGroup>

            // PB progression only makes sense for results and analytics chart
            var pbs = (this.state.mode === 0 || this.state.mode === 3) ? <></> : <FormControlLabel control={<Checkbox />} label="PB progression" onChange={this.handlePBS} checked={this.state.pbs} />

            var profile = <RunnerProfile selID={this.state.selRunnerID} />
            var page = <RunnerPage selID={this.state.selRunnerID} mode={this.state.mode} filter={this.state.filter} achievements={this.state.pbs} />;

            return <div>
                {appbar}
                <div className='paper dr-runner-title'>
                    <div className='card' style={{flexShrink: 1, flexGrow: 1, flexBasis: '45%' }}>
                        {profile}
                    </div>
                    <div className='card' style={{flexShrink: 1, flexGrow: 0 }}>
                        {options}
                        {pbs}
                    </div>
                </div>
                {page}
            </div>
        } else {
            return <div>
                {appbar}
                <AllRunners onSelectRunner={this.onSelectRunner} />
                </div>;
        }
    }
}
