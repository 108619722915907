import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('useGlobalContext must be used with a GlobalContextProvider');
  }

  return context;
};