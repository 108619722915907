
import React, { useEffect } from 'react';
import { ISANDROIDWEB, ISIOSWEB, PLATFORM, VERSION } from './Util';
import Splash1 from '../images/Splash1.png';
import Splash2 from '../images/Splash2.png';
import DRIcon from '../images/DRLogo2.png';
import { useNavigate } from 'react-router-dom';
import './SplashScreen.css';

 interface SSProps {
  numRunners?: number;
  numFinishers?: number;
  numMeetings?: number;
}

const SplashScreen: React.FC<SSProps> = () => {

  const [ runners, setRunners ] = React.useState('');
  const [ meetings, setMeetings ] = React.useState('');
  const [ finishers, setFinishers ] = React.useState('');

  useEffect(() => {

    const getSplashData  = async () => {
      const numRunners = localStorage.getItem('splash-runners')
      setRunners(numRunners!);
      const numMeetings = localStorage.getItem('splash-meetings')
      setMeetings(numMeetings!);
      const numFinishers = localStorage.getItem('splash-finishers')
      setFinishers(numFinishers!);

    }

    getSplashData().catch(err => console.error(err))
    
  });

  const navigate = useNavigate();

  // Show splash screen for 2s
  useEffect(() => {
    setTimeout(() => {
      navigate('/home')
    }, 2000)
  }, [])

  const stats = () => <div style={{ fontSize: 48, color: 'white', textAlign: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: 'fit-content', margin: 'auto' }}>
    <span>{runners} Runners</span><br />
    <span>{meetings} Events</span><br />
    <span>{finishers} Results</span><br />
    <span style={{ fontSize: 24 }}>{"Version " + VERSION}</span>
  </div>

  const splashnum = Math.floor(Math.random() * 2);
  const image = splashnum === 0 ? Splash1 : Splash2;

  let style = PLATFORM === 'ios' ? { paddingTop: '30px' } : {};

  return (
    <>
        <div className={'splash-header-container'} style={style}>
          <img className={'icon splash-header-icon'} src={DRIcon} alt="DR" />
          <div className={'splash-header-text'}>Dulwich Runners</div>
        </div>
    <div style={{ width: '100%', position: 'relative' }}>
      {
          (PLATFORM === 'web' && !ISIOSWEB && !ISANDROIDWEB) && <img src={image} alt="" width='100%' />
      }
      {
          (PLATFORM !== 'web' || ISIOSWEB || ISANDROIDWEB) && (<><img src={Splash1} alt="" width='100%' /><img src={Splash2} alt="" width='100%' /></>)
      }
     
      {stats()}
    </div>
  </>
  )
}

export default SplashScreen;