import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, IconButton } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FlagIcon from '@mui/icons-material/FlagOutlined';
import StarIcon from '@mui/icons-material/StarOutlineOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { TBar, ABar, SCREEN, formatAwards } from './Widgets';
import { Filter, FilterPrefs, FilterDrawer } from './Filter';

import { MONTHS, formatTime, formatAGRes, formatDateString,
    Runner, Finisher, Meeting, getRunner, formatPB, getPBString, ETYPES, ETYPES_DESCR, formatDate, eventIconText, sortKeys, serverRequest, serverRequestBlob, ISNATIVE } from './Util';
import '../Running.css';
import { GlobalContext } from '../context/GlobalContextProvider';

interface SingleMeetingProps {
    finishers: {};
    meetingID: number;
    selRunnerID: string;
    showDescr: boolean;
}

interface SingleMeetingState {}

export class SingleMeeting extends React.Component<SingleMeetingProps, SingleMeetingState> {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const finishers: {} = this.props.finishers;
        var event_types: string[] = []
        var keys;

        var has_main_events = false;
        keys = sortKeys(finishers, 'Time', 'Pos', true, true);

        // Finds all events in the meeting
        for (var key in keys) {
            var fkey = keys[key]
            var fin: Finisher = finishers[fkey];

            if (event_types.indexOf(fin.Event) < 0) {
                event_types.push(fin.Event);
                if (fin.EType === 'PR' || fin.EType === 'R' || fin.EType === 'T' || fin.EType === 'MT')
                // if (ALL_EVENTS.indexOf(fin.Event) >= 0)
                    has_main_events = true;
            }
        }

        var tables: JSX.Element[] = [];
        for (var n=0; n<event_types.length; n++) {
            var date = '';
            var venue = '';
            var meeting = '';
            var event = '';
    
            var rows: JSX.Element[] = [];

            for (key in keys) {
                fkey = keys[key]
                fin = finishers[fkey];

                if (fin.Event !== event_types[n])
                    continue;

                var runner: Runner = getRunner(fin.ID);
                if (runner === undefined)
                    continue
    
                // var is_main_event = (ALL_EVENTS.indexOf(fin.Event) >= 0);
                var is_main_event = fin.EType === 'PR' || fin.EType === 'R' || fin.EType === 'T' || fin.EType === 'MT';

                if (date === '') {
                    date = fin.Date;
                    venue = fin.Venue;
                    meeting = fin.Meeting;
                    event = fin.Event;
                }

                var pb = formatPB(fin, false);
                var agres = is_main_event ? formatAGRes(fin.AGRes): '';

                rows.push(<tr key={key} data-key={fin.MeetingID} className={this.props.selRunnerID === fin.ID ? 'selectedrow' : ''}>
                        <td>{fin.Pos}</td>
                        <td><b>{runner.First + " " + runner.Last}</b></td>
                        <td><b>{formatTime(fin.Time, fin.Event)}</b></td>
                        { is_main_event && pb }
                        { is_main_event && <td>{agres}</td> }
                        <td>{fin.Gender + " " + fin.AG}</td>                        
                    </tr>);
            }

            if (this.props.showDescr) {
                var details = <table className='detailstable' key={meeting + event}>
                        <tbody>
                            <tr><td><b>{meeting}</b></td></tr>
                            <tr><td>{formatDateString(date) + " | " + venue + " | " + event + " | "}
                                    <a href={"https://www.thepowerof10.info/results/results.aspx?meetingid=" + this.props.meetingID} target="_blank" rel="noreferrer">Po10: {this.props.meetingID}</a>
                                    </td>
                            </tr>
                        </tbody></table>
                tables.push(details);
            } else if (event_types.length > 1) {
                // always show the event name when there are multiple events in the same meeting
                details = <div className='largetext'>{event}</div>
                tables.push(details);
            }

            var head: JSX.Element = <div></div>;
            if (rows.length > 0) {
                if (has_main_events)
                    head = <tr><th>Pos</th><th>Name</th><th>Time</th><th></th><th colSpan={2}>AG</th></tr>;
                else
                    head = <tr><th>Pos</th><th>Name</th><th>Time</th><th>AG</th></tr>;
                tables.push(<table key={"trailer" + event} className='bicolortable'><thead>{head}</thead><tbody>{rows}</tbody></table>)
            }
        }

        return <div>{tables}</div>
    }
}

interface MeetingFinishersState {
    race: {};
}

interface MeetingFinishersProps {
    raceID: number;
    runnerID: string;
    showDescr: boolean;
}

export class MeetingFinishers extends React.Component<MeetingFinishersProps, MeetingFinishersState> {
    constructor(props) {
        super(props);
        this.state = {race: {} };
    }

    componentDidMount() {
        this.loadRace(this.props.raceID);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.raceID !== this.props.raceID)
            this.loadRace(this.props.raceID);
    }

    loadRace(id) {
        if (id === 0)
            return;

        serverRequest("meeting?meetID=" + id)
        .then(res => {
            this.setState({race: res });
        });
    }

    render() {
        return <SingleMeeting meetingID={this.props.raceID} finishers={this.state.race} showDescr={this.props.showDescr} selRunnerID={this.props.runnerID} />
    }
}

function arrayEquals(a, b) {
    return Array.isArray(a) && Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
}

interface MeetingGroupProps {
    datestr: string;
    nraces: number;
    nrunners: number;
    nevents: number[];
    awards;
}

function MeetingGroup(props: React.PropsWithChildren<MeetingGroupProps>) {
    var events = '';
    for (var n=0; n<props.nevents.length; n++) {
        if (props.nevents[n] > 0)
            events += props.nevents[n] + " " + ETYPES_DESCR[n] + " | "
    }
    if (events.length > 3)
        events = events.substr(0, events.length-3);

    return <Accordion sx={{ backgroundColor: 'var(--group-color)' }} >
            <AccordionSummary expandIcon={<ExpandMoreIcon fontSize='large' /> } >
                <div style={{ display: 'grid', gridTemplateColumns: '40fr 60fr', color: 'var(--title-color)', gridGap: '5px', marginRight: '10px', width: '100%'}}>
                    <div style={{gridColumn: '1', gridRow: '1', fontSize: '1.4rem', paddingRight: '5px'}} >
                        <b>{props.datestr}</b>
                    </div>
                    <div style={{ gridColumn: '2', gridRow: '1', fontSize: '1.2rem'}} >
                        {events}
                    </div>
                    <div style={{ gridColumn: '1', gridRow: '2' }}>
                        {props.nraces}<FlagIcon width='16px' height='16px' />
                        { }
                        {props.nrunners}<DirectionsRunIcon width='16px' height='16px' />
                    </div>
                    <div style={{ gridColumn: '2', gridRow: '2'}} >
                        {props.awards}
                    </div>
                </div>
            </AccordionSummary> 
            <AccordionDetails sx={{ p: 0 }} >
                {props.children}
            </AccordionDetails>
        </Accordion>

        // , backgroundColor: 'var(--paper-color)' 
}

// WTF!!!
function mod(n, m) {
    return ((n % m) + m) % m;
}

function getDateGroup(date: Date, groupby: string): string {
    switch (groupby) {
        case 'W':
            var day = date.getDay()
            var days_back = mod(day-1, 7);  // Mondays -> 0, Sundays -> 6
            var last_monday: Date = new Date(date);
            last_monday.setDate(last_monday.getDate() - days_back);
            var next_sunday: Date = new Date(last_monday);
            next_sunday.setDate(next_sunday.getDate() + 6);

            if (last_monday.getMonth() !== next_sunday.getMonth())
                return formatDate(last_monday, true) + "-" + formatDate(next_sunday, false);
            else
                return last_monday.getDate() + "-" + formatDate(next_sunday, false);
        case 'M':
            return MONTHS[date.getMonth()] + " " + date.getFullYear();
        case 'Y':
            return date.getFullYear().toFixed(0);
        case 'D':
        default:
            return formatDate(date);
    }
}

// TBD: adapt to screen size, and use two columns on very large screens
function getMeetingCard(key: string, race: Meeting, isOpen: boolean, onSelectRace, screenSize: number) {
    var awards = formatAwards(race.NumTop10, race.NumYTop10, race.NumPB, race.NumSB, true);
    var [event_icon, event_text] = eventIconText(race.Event, race.EType);
    var datestr = formatDateString(race.Date);

    // TBD: DR own races should not have a link (it won't work)
    var link = <a href={"https://www.thepowerof10.info/results/results.aspx?meetingid=" + race.MeetingID} target="_blank" rel="noreferrer">{"Po10: " + race.MeetingID}</a>
    var expandIcon = isOpen ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />

    var finishers = <div></div>;
    var classes;
    if (isOpen) {
        finishers = <div style={{ borderTop: '1px solid var(--separator-color)', paddingBottom: '5px'}} >
                        {link}
                        <MeetingFinishers key={"B" + race.MeetingID + race.Event} raceID={race.MeetingID} runnerID={''} showDescr={false} />
                    </div>;
        classes = 'gridcard cardselected';
    } else {
        classes = 'gridcard card';
    }

    return  <div className={classes} style={{ gridTemplateColumns: '15fr 75fr 10fr'}} key={key} onClick={onSelectRace} data-key={race.MeetingID}>
        <div style={{gridColumn: '1', gridRow: '1'}} >{event_icon}</div>
        <div style={{gridColumn: '1', gridRow: '2'}} >{event_text}</div>
        <div style={{gridColumn: '1', gridRow: '3'}} >{race.Num}<DirectionsRunIcon /></div>
        <div style={{gridColumn: '2', gridRow: '1', fontSize: '1.6rem', fontWeight: 'bold'}} >{race.Meeting}</div>
        <div style={{gridColumn: '2', gridRow: '2'}} >{datestr}{' - '}{race.Venue}</div>
        <div style={{gridColumn: '2', gridRow: '3'}} >{awards}</div>
        <div style={{gridColumn: '3', gridRow: '3'}} >{expandIcon}</div>
        <div style={{gridColumn: '1/4', gridRow: '4'}} >{isOpen && finishers}</div>
    </div>
}

export function getFinisherCard(key: string, fin: Finisher, isOpen: boolean, onSelectRace, variant: string) {
    var awards = getPBString(fin, false);
    var award_color = fin.PB === 2 ? 'var(--text-highlight)' : 'var(--text-highlight-2)';
    var [event_icon, event_text] = eventIconText(fin.Event, fin.EType);
    var datestr = formatDateString(fin.Date);
    var link = <a href={"https://www.thepowerof10.info/results/results.aspx?meetingid=" + fin.MeetingID} target="_blank" rel="noreferrer">{"Po10: " + fin.MeetingID}</a>
    // var is_main_event = (ALL_EVENTS.indexOf(fin.Event) >= 0);
    var is_main_event = fin.EType === 'PR' || fin.EType === 'R' || fin.EType === 'T' || fin.EType === 'MT';
    var agres = is_main_event ? formatAGRes(fin.AGRes): '';
    var expandIcon = isOpen ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />

    var finishers = <></>;
    if (isOpen)
        finishers = <MeetingFinishers key={"B" + fin.MeetingID + fin.Event} raceID={fin.MeetingID} runnerID={fin.ID} showDescr={false} />;

    var primary, secondary;
    if (variant === "normal") {
        primary = fin.Meeting;
        secondary = datestr + ' | ' + fin.Venue;
    } else {
        var runner = getRunner(fin.ID)
        primary = runner.First + " " + runner.Last;
        secondary = datestr + ' | ' + fin.Meeting + ' | ' + fin.Venue;
    }

    return <div>
            <div className='gridcard card' style={{ gridTemplateColumns: 'minmax(auto,15fr) 75fr 10fr'}} key={key} onClick={onSelectRace} data-key={fin.MeetingID}>
                <div style={{gridColumn: 1, gridRow: 1}} >{event_icon}</div>
                <div style={{gridColumn: 1, gridRow: 2}} >{event_text}</div>

                <div style={{gridColumn: 2, gridRow: 1}} >
                    <div style={{display: 'flex', flex: '1 1 100%', flexWrap: 'wrap', alignContent: 'center', columnGap: '5px'}}>
                            <div style={{flexGrow: 1, flexBasis: 'var(--field-width)', fontSize: '1.6rem', fontWeight: 'bold'}}>{primary}</div>
                            <div style={{flexGrow: 1, flexBasis: 'var(--field-width)', color: 'var(--plain-color)'}}>{secondary}</div>
                    </div>
                </div>
                <div style={{gridColumn: 2, gridRow: 2}} >
                    <div className='flexcard'>
                        <div style={{flex: '0 0', order: 1, borderRight: '1px solid var(--table-border-color)', paddingRight: '10px'}}><span style={{color: 'grey'}}>Pos</span><br/><span style={{fontSize: '1.6rem'}}>{fin.Pos}</span></div>
                        <div style={{flex: '0 0', order: 2, borderRight: '1px solid var(--table-border-color)', paddingRight: '10px'}}><span style={{color: 'grey'}}>Time</span><br/><span style={{fontSize: '1.6rem', fontWeight: 'bold'}}>{formatTime(fin.Time, fin.Event)}</span></div>
                        <div style={{flex: '0 0', order: 3, borderRight: '1px solid var(--table-border-color)', paddingRight: '10px'}}>{fin.Gender + " " + fin.AG}<br/><span style={{fontSize: '1.6rem'}}>{agres}</span></div>
                        <div style={{flex: '0 0', order: 4, color: award_color}}>{awards}</div>
                    </div>
                </div>
                <div style={{gridColumn: 3, gridRow: 2}} >{expandIcon}</div>
            </div>
            <div style={{gridColumn: '1/4', gridRow: 3}} >{isOpen && link}</div>
            <div style={{gridColumn: '1/4', gridRow: 4}} >{isOpen && finishers}</div>
        </div>
}

export function getLeaderCard(runner: Runner, fin: Finisher, pos: number, variant: string, isOpen: boolean, onSelectRace) {
    var cname = isOpen ? 'flexcard card link cardselected' : 'flexcard card link';
    var date = (variant === 'simple') ? '' : formatDateString(fin.Date);
    var finishers = isOpen ? <MeetingFinishers raceID={fin.MeetingID} runnerID={runner.ID} showDescr={true} /> : <></>;
    var namediv = (variant === 'runner') ? <></> : <div style={{flex: '0 0 20ch'}}><b>{runner.First + " " + runner.Last}</b></div>

    var style = {}
    if (fin.PB === 2)
        style = {color: 'var(--text-highlight)'}
    else if (fin.PB === 1)
        style = {color: 'var(--text-highlight-2)'}

    var pb = <></>
    if (fin.PB > 0)
        pb = <StarIcon style={style} />

    var card = <div>
        <div className={cname} style={{justifyContent: 'space-between', flexWrap: 'nowrap', width: '100%'}} key={pos} onClick={onSelectRace} data-key={fin.MeetingID} runner-key={runner.ID}>
            <div style={{flex: '0 0 3ch'}}>{pos}</div>
            {namediv}
            <div style={{flex: '0 0 7ch', fontSize: '1.6rem'}}>{formatTime(fin.Time, fin.Event)}</div>
            <div style={{display: 'flex', flex: '1 1 20ch', flexWrap: 'wrap', alignContent: 'center', fontSize: '1.2rem', columnGap: '5px', color: 'var(--plain-color)'}}>
                <div style={{flexGrow: 1, flexBasis: 'var(--field-width)'}}>{fin.Venue}</div>
                <div style={{flexGrow: 1, flexBasis: 'var(--field-width)'}}>{date}</div>
            </div>
            <div style={{display: 'flex', flex: '1 1 20ch', flexWrap: 'wrap', alignContent: 'center', fontSize: '1.2rem', columnGap: '5px', color: 'var(--plain-color)'}}>
                <div style={{flexGrow: 1, flexBasis: 'var(--field-width)'}}>{pb}{fin.Gender + " " + fin.AG}</div>
                <div style={{flexGrow: 1, flexBasis: 'var(--field-width)'}}>{formatAGRes(fin.AGRes)}</div>
            </div>
        </div>
        {isOpen && finishers}
    </div>

    return card;
}

interface MeetingsProps {
    races: {};
    start_grouped: boolean;
    group_by: string;
    loading: boolean;
}

interface MeetingsState {
    selRaceID: number;
    grouped: boolean[];
    is_sparse: boolean;
}

export class Meetings extends React.Component<MeetingsProps, MeetingsState> {
    constructor(props) {
        super(props);
        this.state = { selRaceID: 0, grouped: [], is_sparse: false };
        this.onSelectRace = this.onSelectRace.bind(this);
    }

    componentDidMount() {
        this.recalc();
    }

    componentDidUpdate(prevProps) {
        if (! arrayEquals(Object.keys(prevProps.races), Object.keys(this.props.races)))
            this.recalc();
    }

    // Calculates the number of groups, given the group_by prop
    // and decides if the table is sparse (so will be displayed without grouping)
    recalc() {
        var lastdate = '';
        var ngroups = 0;
        var is_sparse = true;
        var racekeys = Object.keys(this.props.races);

        if (racekeys.length > 10) {  // a small number of races is considered sparse and displayed without groups
            for (var n=0; n<racekeys.length; n++) {
                var key = racekeys[n];
                var race: Meeting = this.props.races[key];
                var group = getDateGroup(new Date(race.Date), this.props.group_by);
                if (lastdate !== group)
                    ngroups += 1;
                lastdate = group;
            }
            is_sparse = (ngroups * 2) > racekeys.length;
        }

        // Decides if it's a sparse date list and prepares the collapsible groups
        this.setState( { is_sparse: is_sparse, grouped: is_sparse ? [] : new Array(ngroups).fill(!this.props.start_grouped) } );
    }
    
    onSelectRace(e) {
        const ID = Number(e.currentTarget.getAttribute('data-key'));
        this.setState( { selRaceID: this.state.selRaceID === ID ? 0 : ID } );
    }

    getCounters() {
        var races = this.props.races;
        var racekeys = Object.keys(races);
        var nraces = 0, nrunners = 0, numpb = 0, numsb = 0, numtop10 = 0, numytop10 = 0;
        var num_et: number[] = new Array(ETYPES.length).fill(0);
        for (var n=0; n<racekeys.length; n++) {
            var key = racekeys[n];
            var race: Meeting = races[key];
            nraces += 1;
            nrunners += race.Num;
            numpb += race.NumPB;
            numsb += race.NumSB;
            numtop10 += race.NumTop10;
            numytop10 += race.NumYTop10;
            var et_ind = ETYPES.indexOf(race.EType);
            if (et_ind >= 0)
                num_et[et_ind] += 1;
        }

        return [nraces, nrunners, numpb, numsb, numtop10, numytop10, num_et]
    }

    render() {
        if (this.props.loading)
            return <h2>Loading...</h2>

        const ScreenWidth = SCREEN.SMALL; // useMediaQuery({ query: '(min-width: 800px)' }) ? SCREEN.LARGE : (useMediaQuery({ query: '(min-width: 500px)' }) ? SCREEN.MEDIUM : SCREEN.SMALL);
        
        var races = this.props.races;
        var racekeys = Object.keys(races);
        racekeys = sortKeys(races, 'Date', 'Num', false, false);  // TBD sort by date, num, meeting

        if (racekeys.length === 0)
            return <h2>No events match your selection</h2>

        var cards: JSX.Element[] = [];
        var groups: JSX.Element[] = [];
        var lastdate = '';
        var nraces = 0, nrunners = 0, numpb = 0, numsb = 0, numtop10 = 0, numytop10 = 0;
        var num_et: number[] = new Array(ETYPES.length).fill(0);
        for (var n=0; n<racekeys.length; n++) {
            var key = racekeys[n];
            var race: Meeting = races[key];

            // Have we moved to a new date?
            var newdate = getDateGroup(new Date(race.Date), this.props.group_by);
            if (!this.state.is_sparse && lastdate !== newdate) {
                if (cards.length > 0) {
                    groups.push(<MeetingGroup key={lastdate}
                                        datestr={lastdate}
                                        nraces={nraces}
                                        nrunners={nrunners}
                                        nevents={num_et}
                                        awards={formatAwards(numtop10, numytop10, numpb, numsb, false)}
                                        >
                                    {cards}
                                </MeetingGroup>);
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    nraces = 0; nrunners = 0; numpb = 0; numsb = 0; numtop10 = 0; numytop10 = 0;
                    num_et = new Array(ETYPES.length).fill(0);  // if I don't create a new array, I effectively change the props of all previous groups...
                    cards = [];
                }
            }

            lastdate = newdate;
            nraces += 1;
            nrunners += race.Num;
            numpb += race.NumPB;
            numsb += race.NumSB;
            numtop10 += race.NumTop10;
            numytop10 += race.NumYTop10;
            var et_ind = ETYPES.indexOf(race.EType);
            if (et_ind >= 0)
                num_et[et_ind] += 1;

            cards.push(getMeetingCard(key, race, race.MeetingID === this.state.selRaceID, this.onSelectRace, ScreenWidth));
        }

        if (!this.state.is_sparse && cards.length > 0) {
            groups.push(<MeetingGroup key={lastdate}
                            datestr={lastdate}
                            nraces={nraces}
                            nrunners={nrunners}
                            nevents={num_et}
                            awards={formatAwards(numtop10, numytop10, numpb, numsb, false)}
                        >
                            {cards}
                        </MeetingGroup>);
        }

        return this.state.is_sparse ? <div className='cardset'>{cards}</div> : <div>{groups}</div>
    }
}

interface MeetingListProps {
    filter: string;
    start_grouped: boolean;
    group_by: string;  // '', 'D', 'W', 'M', 'Y' - none, day, week, month, year
    print: boolean;
}

interface MeetingListState {
    races: {};
    loading: boolean;
}

export class MeetingList extends React.Component<MeetingListProps, MeetingListState> {
    constructor(props) {
        super(props);
        this.state = { races: {}, loading: false };
        this.onPrint = this.onPrint.bind(this);
    }

    componentDidMount() {
        this.loadRaces();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter)
            this.loadRaces();
    }
    
    loadRaces() {
        this.setState({loading: true});

        serverRequest("race?" + this.props.filter)
        .then(res => {
            this.setState({races: res, loading: false });
        });
    }

    onPrint() {
        serverRequestBlob("printresults?" + this.props.filter)
        .then(blob => {
            var url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            const fname = "Non-Parkrun Results.pdf";
            link.href = url;
            link.setAttribute('download', fname);
            link.click();
        });
    }

    render() {
        var list = <Meetings races={this.state.races} start_grouped={this.props.start_grouped} group_by={this.props.group_by} loading={this.state.loading} />
        var print = this.props.print && ISNATIVE ?
                    <IconButton onClick={this.onPrint} >
                        <FileDownloadOutlinedIcon fontSize='large'/>
                    </IconButton> :
                    <></>;

        return (
          <div style={{padding: '16px'}}>
            {print}
            {list}
        </div>)
}
}

interface ResultsPageProps {
}

interface ResultsPageState {
    filterquery: string;
    group_by: string;
}

export class ResultsPage extends  React.Component<ResultsPageProps, ResultsPageState> {

  static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            filterquery: '',
            group_by: 'D',
        }

        this.onChange = this.onChange.bind(this);
    }

    static filter_prefs: FilterPrefs = {year: true, month: true,
        event: true, all_event: true, mainOnly: false,
        gender: false, all_gender: false, age_group: false, all_age_group: false, meeting: true };

    componentDidMount() {
        this.onChange(new Filter(0, 0, '', '', '', false, ''), true);
    }

    onChange(filter: Filter, requery) { 
        var filterquery = '';
        if (filter.year > 0 || filter.event !== '' || filter.meeting.length > 2) {
            filterquery = "meeting=" + filter.meeting + "&year=" + filter.year + "&month=" + filter.month + "&event=" + filter.event; 
        }

        this.setState({ filterquery: filterquery, group_by: (filter.year > 0 && filter.month > 0) ? 'D' : 'W' });
    }

    openMenu(context) {
        context.setMenuOpen(!context.menuOpen);
    }

    render() {
        var filter = <FilterDrawer onChange={this.onChange} prefs={ResultsPage.filter_prefs} start_filter={new Filter(0, 0, '', '', '', false, '')} display_filter={true} />

        var appbar = <div>
            <ABar position="top" style={{top: ISNATIVE ? '35px' : '0'}}>
            <TBar>
                <IconButton sx={{ color: 'var(--paper-color)'}} onClick={() => this.openMenu(this.context)}>
                    <MenuIcon fontSize="large"></MenuIcon>
                </IconButton>
                <div className='largetext' >Results</div>
                <Box sx={{ flexGrow: 1}} />
                {filter}
            </TBar>
            </ABar>
            </div>

      return <div>
                {appbar}
                <MeetingList key={this.state.filterquery} filter={this.state.filterquery} start_grouped={false} group_by={this.state.group_by} print={false}/>
            </div>;
    }
}
