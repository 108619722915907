import React from 'react';
import { FormControlLabel, Grid, TextField, FormControl, Select, MenuItem, InputLabel, Box, Checkbox } from '@mui/material';
import { Drawer, Divider, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';

import {formatDateParam, MONTHS, AGE_GROUPS, ALL_EVENTS, ETYPES, ETYPES_DESCR, IS_ROAD, IS_TRACK, EVENT_TYPES, } from './Util';
import { PlainButton } from './Widgets';

export class Filter {
    year: number = 0;
    month: number = 0;
    event: string = '';
    gender: string = '';
    age_group: string = '';
    mainOnly: boolean = true;
    meeting: string = '';    
    fromdate: Date = new Date(0);  // UNIX epoch
    todate: Date = new Date(0);
    is_ym = true;

    constructor(year: number = 0, month: number = 0, event: string = '', gender: string = '', age_group: string = '', mainOnly: boolean = true, meeting: string = '') {
        this.year = year;
        this.month = month;
        this.event = event;
        this.gender = gender;
        this.age_group = age_group;
        this.mainOnly = mainOnly;
        this.meeting = meeting;
    }

    /*** ONLY STATIC METHODS AS WE NEED TO DEEP COPY THE OBJECT, AND IT BECOMES INSTANCE OF SOMETHINE ELSE... WTF ***/
    static DateRangeFilter(fromdate: Date, todate: Date, event: string = '', gender: string = '', age_group: string = '', mainOnly: boolean = true, meeting: string = '') {
        var filter: Filter = new Filter(0, 0, event, gender, age_group, mainOnly, meeting);
        filter.fromdate = fromdate;
        filter.todate = todate;
        filter.is_ym = false;
        return filter;
    }

    static get_params(filter: Filter) {
        var str;
        if (filter.is_ym) {
            str = "year=" + filter.year + "&month=" + filter.month;
        } else {
            str = "fromdate=" + formatDateParam(filter.fromdate) + "&todate=" + formatDateParam(filter.todate);
        }

        str += "&event=" + filter.event + "&gender=" + filter.gender + "&age_group=" + filter.age_group + "&meeting=" + filter.meeting;
        return str; 
    }
}

export class FilterPrefs {
    year: boolean = false;
    month: boolean = false;
    event: boolean = false;
    gender: boolean = false;
    age_group: boolean = false;
    mainOnly: boolean = false;
    meeting: boolean = false;
    all_event: boolean = false;
    all_gender: boolean = false;
    all_age_group: boolean = false;
}

function EventButton(props) {   
    var bg = props.type === IS_ROAD ? 'var(--table-header-color)' :
            (props.type === IS_TRACK ? 'var(--table-alt-header-color)' : 'var(--secondary-color)');
    
    var border = props.is_selected ? '3px solid var(--primary-color)' : '0px';

    return <div
                onClick={props.onClick}
                data-key={props.dkey}
                style={{padding: '5px', border: border, textAlign: 'center', flex: '1 0', backgroundColor: bg, color: 'var(--light-text-color)'}}
                key={props.dkey}
            >
        {props.text}
    </div>
}

interface EventKeysProps { 
    onSelectEvent;
    selection: string;
    has_all: boolean;
}

interface EventKeysState {
}

class EventKeys extends React.Component<EventKeysProps, EventKeysState> {
    render() {
        var events_road = ALL_EVENTS.map((ev, ind) => { 
            return EVENT_TYPES[ind] === IS_ROAD ? 
                <EventButton onClick={this.props.onSelectEvent} dkey={ev} text={ev} type={IS_ROAD} is_selected={this.props.selection === ev} /> :
                <></>;
        })

        var events_track = ALL_EVENTS.map((ev, ind) => {
            return EVENT_TYPES[ind] === IS_TRACK ? 
                <EventButton onClick={this.props.onSelectEvent} dkey={ev} text={ev} type={IS_TRACK} is_selected={this.props.selection === ev} /> :
                <></>;
        })

        var etypes = ETYPES.map((ev, ind) => {
            if (ev !== 'R' && ev !== 'T')   // skips road and track, for which we detail all events
                return <EventButton onClick={this.props.onSelectEvent} dkey={ev} text={ETYPES_DESCR[ind]} type={0} is_selected={this.props.selection === ev} />
        })

        return <div style={{display: 'flex', flexDirection: 'column', rowGap: '5px', 'fontSize': '1.4rem', cursor: 'pointer'}}>
                    <div style={{display: 'flex', flexDirection: 'row', columnGap: '5px', alignItems: 'center'}}>
                        <div style={{flex: '1 1', borderBottom: '1px solid'}}></div>
                        <div style={{}}>Events</div>
                        <div style={{flex: '1 1', borderBottom: '1px solid'}}></div>
                    </div>
                    { this.props.has_all && <EventButton onClick={this.props.onSelectEvent} dkey='ALL' text='ALL Events' type={0} is_selected={this.props.selection === ''} /> }
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '5px'}}>
                        {events_road}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '5px'}}>
                        {events_track}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '5px'}}>
                        {etypes}
                    </div>
                </div>
    }
}


interface FilterPanelProps {
    onChange;
    onEsc;
    prefs: FilterPrefs;
    start_filter: Filter;
}

interface FilterPanelState {
   filter: Filter;
}

class FilterPanel extends React.Component<FilterPanelProps, FilterPanelState> {
    constructor(props) {
        super(props);

        this.state = {
            filter: this.props.start_filter,
        };

        this.handleAgeGroup = this.handleAgeGroup.bind(this);
        this.handleGender = this.handleGender.bind(this);
        this.handleMain = this.handleMain.bind(this);
        this.handleYear = this.handleYear.bind(this);
        this.handleMonth = this.handleMonth.bind(this);
        this.onSelectEvent = this.onSelectEvent.bind(this);
    }

    componentDidMount() {
        this.onChange(false);
    }

    onChange(requery) {
        this.props.onChange(this.state.filter, requery); 
    }

    handleYear(e) {
        var newfilter = this.state.filter;
        newfilter.year = parseInt(e.target.value);
        this.setState({filter: newfilter}, () => { this.onChange(true);  });
    }

    handleMonth(e) {
        var newfilter = this.state.filter;
        newfilter.month = parseInt(e.target.value);
        this.setState({filter: newfilter}, () => { this.onChange(true);  });
    }
    
    handleAgeGroup(e) {
        var newfilter = this.state.filter;
        newfilter.age_group = e.target.value === 'ALL' ? '' : e.target.value;
        this.setState({filter: newfilter}, () => { this.onChange(true);  });
    }
    
    handleGender(e) {
        var newfilter = this.state.filter;
        newfilter.gender = e.target.value === 'ALL' ? '' : e.target.value;
        this.setState({filter: newfilter}, () => { this.onChange(true);  });
    }

    handleMain(e) {
        var newfilter = this.state.filter;
        newfilter.mainOnly = !this.state.filter.mainOnly; // we invert the value (the checkbox is "controlled")
        this.setState({filter: newfilter}, () => { this.onChange(false);  });
    }

    onSelectEvent(e) {
        const event: string = e.currentTarget.getAttribute('data-key');
        var newfilter = this.state.filter;
        newfilter.event = event === 'ALL' ? '' : event;
        this.setState({filter: newfilter}, () => { this.onChange(true);  });
    }

    onChildClick(e) {
        e.stopPropagation();
    }

    render() {
        var style = {
            fontSize: '1.4rem',
          }

        var this_year = new Date().getFullYear();
        var year_array = Array.from({length: this_year-2000+1}, (_, i) => this_year - i)
        var year_filter = <FormControl fullWidth margin='dense'>
            <InputLabel>Year</InputLabel>
            <Select value={this.state.filter.year} label="Year" onChange={this.handleYear} sx={style} >
                <MenuItem key={0} value={0}>{'ALL'}</MenuItem>
                {year_array.map((y, ind) => { return <MenuItem key={y} value={y}>{y}</MenuItem> })}
            </Select>
        </FormControl>

        var month_filter = <FormControl fullWidth margin='dense'>
            <InputLabel>Month</InputLabel>
            <Select value={this.state.filter.month} label="Month" onChange={this.handleMonth} sx={style} >
                <MenuItem key={0} value={0}>{'ALL'}</MenuItem>
                {MONTHS.map((m, ind) => { return <MenuItem key={ind+1} value={ind+1}>{m}</MenuItem> })}
            </Select>
            </FormControl>

        var event_keys = <EventKeys onSelectEvent={this.onSelectEvent} selection={this.state.filter.event} has_all={this.props.prefs.all_event} />

        var main_filter = <FormControlLabel control={<Checkbox />} label="Main events only" onChange={this.handleMain} checked={this.state.filter.mainOnly} />

        var gender_filter = <FormControl fullWidth margin='dense'>
            <InputLabel sx={style}>Gender</InputLabel>
            <Select value={this.state.filter.gender} label="Gender" onChange={this.handleGender} sx={style}>
                {this.props.prefs.all_gender && <MenuItem key='ALL' value=''>ALL</MenuItem>}
                <MenuItem key='W' value='W'>Women</MenuItem>
                <MenuItem key='M' value='M'>Men</MenuItem>
            </Select>
        </FormControl>

        var ag_filter = <FormControl fullWidth margin='dense'>
            <InputLabel sx={style}>Age Group</InputLabel>
            <Select value={this.state.filter.age_group} label="Age Group" onChange={this.handleAgeGroup} sx={style}>
                {AGE_GROUPS.map((ag, ind) => { return <MenuItem key={ag} value={ag}>{ag}</MenuItem> })}
            </Select>
        </FormControl>
    
        var filter = <Box onClick={this.props.onEsc} >
                <Grid container spacing={1} sx={{p: 1}}>
                    <Grid item xs={6}>{this.props.prefs.year && year_filter}</Grid>
                    <Grid item xs={6}>{this.props.prefs.month && month_filter}</Grid>
                    <Grid item xs={12}>{this.props.prefs.event && event_keys}</Grid>                    
                    <Grid item xs={12}>{this.props.prefs.mainOnly && main_filter}</Grid>
                    <Grid item xs={6}>{this.props.prefs.gender && gender_filter}</Grid>
                    <Grid item xs={6}>{this.props.prefs.age_group && ag_filter}</Grid>
                </Grid>
            </Box>;

            return filter;
    }
}

// prefs: year, month, event, all_event, main, gender, all_gender, ag, venue
interface FilterDrawerProps {
    onChange;
    prefs: FilterPrefs;
    start_filter: Filter;
    display_filter: boolean;
}

interface FilterDrawerState {
    is_open: boolean;
    filter_label: string;
    filter: Filter;
}

export class FilterDrawer extends React.Component<FilterDrawerProps, FilterDrawerState> {
    constructor(props) {
        super(props);
        this.state = { is_open: false,
            filter: this.props.start_filter,
            filter_label: FilterDrawer.filterString(this.props.prefs, this.props.start_filter),
            }
        this.onChange = this.onChange.bind(this);
        this.onEsc = this.onEsc.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleMeeting = this.handleMeeting.bind(this);
    }

    static add_label(curr, add) {
        if (add === '')
            return curr;
        if (curr === '')
            return add;
        return curr + " | " + add;
    }
    
    static filterString(prefs: FilterPrefs, filter: Filter) {
        var date: string = '';
        if (prefs.year && prefs.month && filter.year > 0) {
            date = filter.month > 0 ? MONTHS[filter.month-1] + "-" + filter.year : filter.year.toFixed(0);
        } else if (prefs.year && filter.year > 0) {
            date = filter.year.toFixed(0);
        }

        var event = '';
        if (prefs.event && filter.event !== '') {
            var ind = ETYPES.indexOf(filter.event);
            event = (ind >= 0) ? ETYPES_DESCR[ind]: filter.event;
        }

        var label = date;
        label = FilterDrawer.add_label(label, event);
        label = FilterDrawer.add_label(label, prefs.gender ? (filter.gender !== '' ? (filter.gender === 'W' ? 'Women' : 'Men') : '') : '');
        label = FilterDrawer.add_label(label, prefs.age_group ? (filter.age_group !== '' ? filter.age_group : ''): '');
    
        return label;
    }    

    toggleDrawer(event) {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ is_open: !this.state.is_open });
    };

    onChange(filter, requery) {
        var newfilter = filter;
        newfilter.meeting = this.state.filter.meeting;
        this.setState({filter: newfilter,
                            filter_label: FilterDrawer.filterString(this.props.prefs, newfilter)}, () => { this.props.onChange(newfilter, requery) });
    }

    onEsc() {
        this.setState({ is_open: false });
    }

    handleMeeting(e) {
        var newfilter = this.state.filter;
        newfilter.meeting = e.target.value;
        this.setState({filter: newfilter}, () => { this.props.onChange(newfilter, true) });
    }

    render() {
        var searchbox = <></>;
        if (this.props.prefs.meeting) {
            var style = {
                maxWidth: '10ch',
                color: 'var(--light-text-color)',
                padding: '0px',
                input : {
                    fontSize: '1.6rem',
                    color: 'var(--light-text-color)',
                },
                root: {
                    margin: '0px',
                }
            }

            var searchtext = <TextField placeholder="Venue"
                                variant="standard"
                                value={this.state.filter.meeting}
                                onChange={this.handleMeeting}
                                size='small'
                                id="standard-size-small"
                                sx={style}
                                margin='dense'
                                InputLabelProps={{
                                    style: { color: 'var(--light-text-color)' }
                                }}
                                inputProps={{
                                    color: 'var(--light-text-color)',
                                    padding: '8px 8px 8px 8px',
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />

            searchbox = <div style={{display: 'flex', alignItems: 'center', flexGrow: '1', flexShrink: '1', backgroundColor: alpha('#0051AD', 0.15) }} >
                            <div style={{flexGrow: '0', padding: '0px 8px 0px 8px' }} >
                                <SearchIcon />
                            </div>
                            <div style={{flexGrow: '1', flexShrink: '1'}} >
                                {searchtext}
                            </div>
                        </div>
    
        }

        var drawer_button = (this.props.display_filter && this.state.filter_label !== '') ?
                                <PlainButton action={this.toggleDrawer} style={{color: 'var(--light-text-color)', backgroundColor: 'var(--secondary-color)', border: '0px'}} title={this.state.filter_label} /> :
                                <IconButton onClick={this.toggleDrawer} style={{color: 'var(--light-text-color)' }} ><FilterAltIcon fontSize="large" /></IconButton>

        return <Box style={{left: 'auto', right: '0', maxWidth: '60%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }} >
                    {searchbox}
                    <div style={{flexGrow: '0', flexShrink: '1'}} >
                        {drawer_button}
                    </div>
                </div>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={this.state.is_open}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <IconButton onClick={this.toggleDrawer}>
                        <ChevronRightIcon fontSize="large" />
                    </IconButton>
                    </div>
                    <Divider />
                    <Box sx={{ width: '24em', height: '16em' }} role="presentation" >
                        <FilterPanel onChange={this.onChange} onEsc={this.onEsc} prefs={this.props.prefs} start_filter={this.props.start_filter} />
                    </Box>
                </Drawer>
            </Box>
    }
}
