import { ListItemButton, ListItemIcon } from "@mui/material"
import React, { ReactElement } from "react"
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../hooks/useGlobalContext";

interface SideMenuButtonProps {
  title: string;
  route: string;
  icon: ReactElement
  
}
export const SideMenuButton: React.FC<SideMenuButtonProps> = ({title, route, icon}) => {

  const { setMenuOpen } = useGlobalContext();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/${route}`);
    setMenuOpen(false);
  }

  return (
    <ListItemButton onClick={handleButtonClick}>
      <ListItemIcon sx={{ color: "var(--paper-color)" }}>
        {icon}
      </ListItemIcon>
      {title}
    </ListItemButton>
  )
}