import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TextField, FormControl, Select, MenuItem, InputLabel, Box, InputAdornment, OutlinedInput } from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatTime, serverRequest } from './Util';
import { AGResChart } from './Charts';

const AGRESMAX = 110;
const AGRESMIN = 30;

interface RunnerAgeGradeProps { 
    rID;
}

interface AllRunnersState {
    date: Dayjs;
    time: Dayjs | null;
    agres: number;
    event: string; 
    fromtime: boolean;
}

export class RunnerAgeGrade extends React.Component<RunnerAgeGradeProps, AllRunnersState> {
    constructor(props) {
        super(props);
        this.state = {date: dayjs(), time: dayjs("00:20:00", "HH:mm:ss"), agres: 0, event: '5K', fromtime: true};
        this.handleDate = this.handleDate.bind(this);
        this.handleTime = this.handleTime.bind(this);
        this.handleAGRes = this.handleAGRes.bind(this);
        this.selectEvent = this.selectEvent.bind(this);
    }

    componentDidMount() {
        this.calc();
    }

    calc() {
        if (this.state.fromtime) {
            if (this.state.time != null && this.state.time.isValid()) {
                serverRequest("agcalc?ID=" + this.props.rID + "&time=" + this.state.time.format('HH:mm:ss') +
                                        "&date=" + this.state.date.format('YYYY-MM-DD') + "&event=" + this.state.event)
                .then(res => {
                    this.setState({agres: res.toFixed(2)});
                });    
            }
        } else {
            var agres = typeof(this.state.agres) == 'string' ? parseFloat(this.state.agres) : this.state.agres;
            if (agres > AGRESMIN && agres < AGRESMAX) {
                serverRequest("agcalc?ID=" + this.props.rID + "&agres=" + this.state.agres +
                                    "&date=" + this.state.date.format('YYYY-MM-DD') + "&event=" + this.state.event)
                                    .then(res => {
                                this.setState({time: dayjs(res, "HH:mm:ss")});
                            });    
            }
        }
    }

    selectEvent(e) {
        this.setState({event: e.target.value}, () => { this.calc(); });
    }

    handleDate = (newValue: Dayjs | null) => {
        if (newValue != null)
            this.setState({date: newValue}, () => { this.calc(); });
    };
    
    handleTime = (newValue: Dayjs | null) => {
        if (newValue != null)
            this.setState({time: newValue, fromtime: true}, () => { this.calc(); });
    };
    
    handleAGRes(e) {
        this.setState({agres: e.target.value, fromtime: false}, () => { this.calc(); });
    }

    render() {
        var agres = typeof(this.state.agres) == 'string' ? parseFloat(this.state.agres) : this.state.agres;

        var etypes = ['800', '1500', '1M', '3K', '5K', '10K', 'HM', 'Mar'].map((ev, ind) => {
            return <MenuItem key={ev} value={ev}>{ev}</MenuItem>
        })

        var agcolor: 'error'|'primary'|'secondary' = !this.state.fromtime && (agres < AGRESMIN || agres > AGRESMAX) ? 'error' : (this.state.fromtime ? 'secondary' : 'primary');
        // var agcolor = !this.state.fromtime && (agres < AGRESMIN || agres > AGRESMAX) ? 'red' : (this.state.fromtime ? 'blue' : 'green');
        // var timecolor: 'error'|'primary'|'secondary' = this.state.fromtime ? (this.state.time != null ? 'primary' : 'error') : 'secondary';

        var timeFormat = "mm:ss";
        var timeMask = "__:__";
        if (this.state.event === "HM" || this.state.event === "Mar") {
            timeFormat = "H:mm:ss";
            timeMask = "_:__:__";
        }

        // '& .MuiTextField-root': { m: 1, width: '17ch' },

        var form = 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                    component="form"
                    sx={{
                        '& .MuiFormControl-root': { m: 1 },
                        '& .MuiFormLabel-root': { fontSize: '1.6rem' },
                        '& .MuiOutlinedInput-root': { fontSize: '1.6rem' },
                        }}
                    >
                        <DesktopDatePicker
                                    label="Date"
                                    inputFormat="DD/MM/YYYY"
                                    value={this.state.date}
                                    onChange={this.handleDate}
                                    renderInput={(params) => <TextField
                                        sx={{ width: '20ch' }}
                                        {...params} 
                                        />}
                                    />                
                        <FormControl 
                                    sx={{ width: '14ch' }}
                                    >
                            <InputLabel>Event</InputLabel>
                            <Select value={this.state.event} label="Event" onChange={this.selectEvent}>
                                {etypes}
                            </Select>
                        </FormControl>
                        <DesktopTimePicker
                                    ampmInClock={false}
                                    inputFormat={timeFormat}
                                    mask={timeMask}
                                    label="Finish Time"
                                    value={this.state.time}
                                    onChange={this.handleTime}
                                    renderInput={(params) => <TextField
                                        sx={{ width: '20ch' }}
                                        {...params}
                                        />}
                                    />
                        <FormControl
                                    sx={{ width: '14ch' }}
                                    >
                            <InputLabel>Age Graded</InputLabel>
                            <OutlinedInput
                                    color={agcolor}
                                    value={this.state.agres}
                                    onChange={this.handleAGRes}
                                    size='medium'
                                    type='number'
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                        </FormControl>
                    </Box>
                </LocalizationProvider>

        var chart = <></>
        var table = <></>
        if (this.state.time != null && this.state.time.isValid()) {
            var tref = this.state.time;
            var points: number[] = [];
            for (var i=Math.floor(agres - 2.0); i<=Math.ceil(agres + 2.0); i++)
                points.push(i);

            var perccells = points.map((ag, ind) => {
                return <td>{ag+"%"}</td>
            })

            var agrescells = points.map((ag, ind) => {
                var time = Math.round(agres / ag * (tref.hour() * 3600 + tref.minute() * 60 + tref.second()));
                return <td>{formatTime(time, '5K')}</td>
            })

            table = <table>
                <tbody>
                    <tr><th>AG Res</th>{perccells}</tr>
                    <tr><th>Time</th>{agrescells}</tr>
                </tbody>
            </table>

            if (agres >= AGRESMIN && agres <= AGRESMAX)
                chart = <AGResChart tref={this.state.time.hour() * 3600 + this.state.time.minute() * 60 + this.state.time.second()} agres={agres} />
        }

        return <div style={{ padding: '16px' }}>
                <div className='largetext' style={{paddingBottom: '20px'}}>Age Grading Calculator</div>
                {form}
                {table}
                {chart}
            </div>
    }
}
