import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {  GlobalContextProvider } from './context/GlobalContextProvider';
import { VERSION } from './components/Util';
import { initSession, serverRequest, getOS, setSession, setRunners, formUrl, setSplashScreen } from './components/Util';

var theme = createTheme({
  typography: {
    fontFamily: ['"MaisonNeue"', '"Segoe UI"', '"Helvetica Neue"'].join(','),
    fontSize: 10,
    htmlFontSize: 10
  },
  palette: {
    primary: {
      main: '#1976d2', // '#0051AD',
    },
    secondary: {
      main: '#ED1C24',
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const loadRunners = async () => {
   try {

     await initSession()

     const res = await serverRequest("login?os=" + getOS() + "&version=" + VERSION)

    setSession(res.session);
    setSplashScreen(res.runners, res.finishers, res.meetings)

     serverRequest("runners")
       .then(res => {
         setRunners(res);
       })
       .catch(e => {
         console.log(e);
       });
     var source = new EventSource(formUrl("notifications"));

     source.addEventListener('open', () => {
       // console.log('SSE opened!');
     });

     source.addEventListener('message', (e) => {
       console.log(e);
       /* const data = JSON.parse(e.data); */
       /* TBD: notification of changes - check e.data and determine if we need a notification to the OS, which would reload the home page */
     });

     source.addEventListener('error', (e) => {
       console.error('Error: ', e);
     });

   } catch(err) {
    console.error(err)
   }
}

loadRunners().then(() => {
  // console.log('loaded now render')
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider>
          <App />
        </GlobalContextProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
