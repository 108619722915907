import React, { useState } from "react";

type GlobalContextType = {
  setMenuOpen: (menuOpen: boolean) => void;
  menuOpen: boolean
}

type Props = {
  children: React.ReactNode
}
export const GlobalContext = React.createContext<GlobalContextType>(null!);

  export const GlobalContextProvider: React.FC<Props> = ({ children }): JSX.Element => {

  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <GlobalContext.Provider value={{
      setMenuOpen,
      menuOpen
    }}>
      {children}
    </GlobalContext.Provider>
  );
}