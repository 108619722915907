import React from 'react';
import { Filter } from './Filter';
import { MeetingList } from "./Races";
import { FixedLeaderboard } from "./Records";
import { Achievements } from "./Runner";
import { ISIOSWEB, ISANDROIDWEB, formatDate } from "./Util";
import { ABar, ExpandableSection, TBar, Title } from "./Widgets";
import DRIcon from '../images/DRLogo2.png';
import AppStoreIcon from '../images/applestore.png';
import GooglePlayIcon from '../images/googleplay.png';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useGlobalContext } from '../hooks/useGlobalContext';
import './Home.css';

export function Home() {

  const { menuOpen, setMenuOpen } = useGlobalContext();

  const openMenu = () => {
    // console.log('open menu')
    setMenuOpen(!menuOpen)
  }

  var appbar =
    <ABar position='top'>
      <TBar>
        <IconButton sx={{ color: "var(--paper-color)" }} onClick={openMenu}>
          <MenuIcon fontSize="large"></MenuIcon>
        </IconButton>
        <img className='icon' src={DRIcon} alt="Dulwich Runners" />
        <div className='largetext' style={{ marginLeft: 10 }}>Dulwich Runners</div>
      </TBar>
    </ABar>

  //             <span style={{'position': 'fixed', 'left': '5px', 'bottom': '1em', top: 'auto', fontSize: '1rem'}}>v{VERSION}</span>

  // When we use a web browser on a device (instead of using the App) we show a link to download the App
  var appLink = <></>
  var style = { width: '50%', verticalAlign: 'middle', padding: '16px' };
  if (ISIOSWEB) {
    appLink = <a href="https://apps.apple.com/us/app/drastic-run/id1617116978">
      <img alt='Download on the App Store' src={AppStoreIcon} style={style} />
    </a>
  } else if (ISANDROIDWEB) {
    appLink = <a href='https://play.google.com/store/apps/details?id=run.drastic'>
      <img alt='Get it on Google Play' src={GooglePlayIcon} style={style} />
    </a>
  }

  var now = new Date();
  var now_day = now.getDay();
  var days_back = ((now_day + 1) % 7);  // (day+1)%7 makes days "base" on Saturday=0 instead of Sunday=0
  if (days_back < 1 && now.getHours() < 12) days_back += 7;       // We continue to point to the previous Saturday until Saturday at noon (parkrun results come at around that time)
  var last_saturday = new Date(now);
  last_saturday.setDate(now.getDate() - days_back);
  var filter = Filter.DateRangeFilter(last_saturday, last_saturday, 'parkrun', '', '', false, '');
  var parkrun_1 = <FixedLeaderboard filter={filter} rID='' variant='simple' start_open={false} title={"Parkrun Report " + formatDate(last_saturday)} />

  var prev_saturday = new Date(last_saturday)
  prev_saturday.setDate(last_saturday.getDate() - 7);
  filter = Filter.DateRangeFilter(prev_saturday, prev_saturday, 'parkrun', '', '', false, '');
  var parkrun_2 = <FixedLeaderboard filter={filter} rID='' variant='simple' start_open={false} title={"Parkrun Report " + formatDate(prev_saturday)} />

  // Previous 2 full week (from Monday until today) - we switch to a new week from Monday
  days_back = now_day > 0 ? 13 + now_day : 20 + now_day;
  var last_monday = new Date(now);
  last_monday.setDate(now.getDate() - days_back);
  filter = Filter.DateRangeFilter(last_monday, now, 'notparkrun', '', '', false, '')
  var others = <MeetingList filter={Filter.get_params(filter)} start_grouped={true} group_by='W' print={true} />

  // Achievements from the last ~2 months
  var last_month = new Date(now);
  last_month.setDate(last_month.getDate() - 61);
  filter = Filter.DateRangeFilter(last_month, now, '', '', '', false, '')
  var achievements = <Achievements filter={Filter.get_params(filter)} />

  return <div>
    {appbar}
    {appLink}
    <div className={'dr-home-container'}>
      <div className={'paper dr-home-weekly-container'}>
        <Title text="Weekly Reports" />
        {parkrun_1}
        {parkrun_2}
        <ExpandableSection title="Latest Achievements" details={'All-time top-10, Season top-10 and PB'}>
          {achievements}
        </ExpandableSection>
      </div>
      <div className={'paper dr-home-other-results-container'}>
        <Title text="Other Results" />
        {others}
      </div>
    </div>
  </div>
}