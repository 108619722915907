import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Home } from './components/Home';
import { RunnerSelector } from './components/Runner';
import { ResultsPage } from './components/Races';
import { CustomLeaderboard, Progressions, Rankings } from './components/Records';
import { Parkrun } from './components/Parkrun';
import { H2H, SeriesSelector, Stats } from './components/Club';
import { About } from './components/About';
import { SideMenu } from './components/SideMenu/SideMenu';
import SplashScreen  from './components/SplashScreen';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Filter } from './components/Filter';
import { dateAdd } from './components/Util';

function App() {

  const [appInsets, setAppInsets] = React.useState({ top: 0, bottom: 0 })

  useEffect(() => {
    const setupWithInsets = async function () {
      const insets = await SafeArea.getSafeAreaInsets()
      appInsets.top = insets.insets.top;
      appInsets.bottom = insets.insets.bottom
      setAppInsets({ ...appInsets })
    }
    setupWithInsets();
  }, []);

  // Note: / should redirect to /splash (how to do it in version 6? https://reactrouter.com/en/main/upgrading/v5)
  return (
      <BrowserRouter>
        <SideMenu />
        <Routes>
          <Route path="*" element={<SplashScreen />} />
          <Route path="/splash"       element={<SplashScreen />} />
          <Route path="/home"         element={<Home/>} />
          <Route path="/runners"      element={<RunnerSelector />} />
          <Route path="/races"        element={<ResultsPage />} />
          <Route path="/leaderboard"  element={<Rankings />} />
          <Route path="/parkrun"      element={<Parkrun />} />
          <Route path="/about"        element={<About />} />
          <Route path="/headtohead"   element={<H2H />} />
          <Route path="/progressions" element={<Progressions />} />
          <Route path="/champs"       element={<SeriesSelector />} />
          <Route path="/stats"        element={<Stats />} />
          <Route path="/about"        element={<About />} />
          <Route path="/customleaderboard"
              element={<CustomLeaderboard start_filter={Filter.DateRangeFilter(dateAdd(new Date(), -14), new Date(), 'parkrun', '', '', false, '')}
                        start_open={true} title='Custom Leaderboard' />} />
          <Route path="/loading"      element={<SplashScreen />} />
        </Routes>
      </BrowserRouter>
  );
}



export default App;
