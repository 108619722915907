import React from "react";
import { IconButton } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { PageHeader } from "./PageHeader/PageHeader";
import { serverRequest, VERSION, getLastSaturdayDate, formUrl } from "./Util";

interface AboutProps { }
interface AboutState {
  about: string;
}

export class About extends React.Component<AboutProps, AboutState> {
  constructor(props) {
    super(props);
    this.state = { about: '' }
  }

  componentDidMount() {
    this.loadAbout();
  }

  loadAbout() {
    serverRequest("about")
      .then(res => {
        this.setState({ about: res.about });
      });
  }

  onDownload() {
    const lastSat = getLastSaturdayDate();

    // Sample code for fetching a webpage and saving it as a Blob
    const path = 'https://www.parkrun.com/results/consolidatedclub/?clubNum=459&eventdate=' + lastSat;
    console.log("Trying to access " + path);
    
    // fetch(path, { method: 'GET', headers: { "Content-Type": "application/x-www-form-urlencoded", "Accept": "text/html"} } )
    fetch(path)
    .then(response => response.text())
    .then(htmlContent => {
        var url = formUrl("uploadparkrun?date=" + lastSat);
        fetch(url, { method: 'POST',
                     body: htmlContent })
        .then(res => {
          if (res.ok) {
            console.log("Parkrun report sent correctly");
          } else {
            console.error('Parkrun report send failed');
          }
          console.log(res)
          return res.json();
        })
        .catch(error => {
          // Handle network errors or other issues
          console.error('An error occurred:', error);
        });
    })
    .catch(error => console.error(error));
  }

  render() {
    var download = <IconButton onClick={this.onDownload} >
                    <FileDownloadOutlinedIcon fontSize='large'/>
                </IconButton>

    return (
      <>
      <PageHeader title="About"></PageHeader>
      {download}
      <div style={{ padding: '16px' }}>
        <h1>DRastic.run Version {VERSION}</h1>
        <div dangerouslySetInnerHTML={{ '__html': this.state.about }} />
      </div>
      </>
    )
  } 
}
