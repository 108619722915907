import { Box, IconButton } from "@mui/material"
import { ABar, TBar } from "../Widgets"
import React from 'react';
import { useGlobalContext } from "../../hooks/useGlobalContext";
import DRIcon from '../../images/DRLogo2.png';
import MenuIcon from '@mui/icons-material/Menu';

interface PageHeaderProps {
  title?: string;
  showIcon?: boolean;
  filter?: any;
  filterLabel?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, showIcon, filter, filterLabel }) => {

  const { menuOpen, setMenuOpen } = useGlobalContext();

  const openMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <ABar position='top'>
      <TBar>
        <IconButton sx={{ color: "var(--paper-color)" }} onClick={openMenu}>
          <MenuIcon fontSize="large"></MenuIcon>
        </IconButton>
        {showIcon ? <img className='icon' src={DRIcon} alt="" /> : ''}
        <div className='largetext' style={{ marginLeft: 10 }}>{title}</div>
        {filter ? 
        <>
            <Box sx={{ flexGrow: 1 }} />
            {/* {filterLabel} */}
            {filter}
        </>
        : ''
        }
      </TBar>
    </ABar>
  )

}