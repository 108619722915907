import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { VictoryLine, VictoryChart, VictoryAxis, VictoryLabel, VictoryTooltip, VictoryVoronoiContainer, VictoryLegend, VictoryScatter } from 'victory';
import { equalArrays, Finisher, sortKeys, MONTHS, formatTime, getRunner, formatShortName, Runner, serverRequest } from './Util';
import { red, indigo, teal, green, orange, purple } from '@mui/material/colors';

/*
function simpleTooltipFormatter(value, name, props) {
    if (name === "date")
        return fullDateFormatter(value)
    if (name === "agres" || name === "max" || name === "min" || name === "avg" || name === "med")
        return value.toFixed(1) + "%"
    return ""
}

function fullDateFormatter(item: number) {
    var d = new Date(item)
    return d.getDate() + "-" + MONTHS[d.getMonth()] + "-" + d.getFullYear().toString().substring(2);
}*/

function longDateTickFormatter(tick, index, ticks) {
    var d = new Date(tick)
    return d.getDate() + "-" + MONTHS[d.getMonth()] + "-" + d.getFullYear().toString().substring(2);
}

function dateGroupTickformatter(tick, groupby) {
    var d = new Date(tick)
    if (groupby === 'M')
        return MONTHS[d.getMonth()] + "-" + d.getFullYear().toString().substring(2);
    if (groupby === 'Q')
        return "Q" + Math.ceil((d.getMonth()+1)/3) + "-" + d.getFullYear().toString().substring(2);
    if (groupby === 'Y')
        return d.getFullYear().toString();
    return d.getDate() + "-" + MONTHS[d.getMonth()] + "-" + d.getFullYear().toString().substring(2);
}

function percTickFormatter(tick, index, ticks) {
    return tick.toFixed(0) + "%";
}

function legendFormatter(item) {
    return longDateTickFormatter(item.datum.date, 0, []) + " " + item.datum.event + "\n" + item.datum.time + " " + item.datum.agres.toFixed(1)+"%"
}

class PlotRec {
    date: number = 0;
    agres: number = 0;
    event: string = '';
    time: string = '';
    dist: number = 0;
}

interface RunnerChartsProps {
    results: {};
}

export class RunnerCharts extends React.Component<RunnerChartsProps> {
    render() {
        var results = this.props.results
        var keys = sortKeys(results, 'Date', null, true);

        var data: PlotRec[] = [];
        var minY = 100;
        var maxY = 0;
        var minX = new Date(2100,1,1).getTime();
        var maxX = 0;
        for (var key in keys) {
            var fkey = keys[key]
            var fin: Finisher = results[fkey];

            if (fin.AGRes > 0) {
                if (fin.AGRes < minY) minY = Math.floor(fin.AGRes);
                if (fin.AGRes > maxY) maxY = Math.ceil(fin.AGRes);
                var dt = new Date(fin.Date).getTime();
                if (dt < minX) minX = dt;
                if (dt > maxX) maxX = dt;

                var pr: PlotRec = new PlotRec();
                pr.dist = fin.Dist;
                pr.agres = fin.AGRes;
                pr.event = fin.Event;
                pr.time = formatTime(fin.Time, fin.Event);
                pr.date = dt;
                data.push(pr);
            }
        }

        if (data.length >= 1) {
            var legend = [
                { name: "short", symbol: {fill: 'white', stroke: colors[0], type: 'triangleUp' } },
                { name: "medium", symbol: {fill: 'white', stroke: colors[1], type: 'circle' } },
                { name: "long", symbol: {fill: 'white', stroke: colors[2], type: 'square' } },
            ]

          return (<div style={{padding: '16px'}}>
                  <VictoryChart
                      domain={{x: [minX, maxX], y: [minY, maxY]}}
                      containerComponent={<VictoryVoronoiContainer/>}
                      theme={runTheme}
                    >
                    <VictoryAxis domain={[minX, maxX]} tickFormat={(t) => dateGroupTickformatter(t, 'M')} tickCount={10} tickLabelComponent={<VictoryLabel angle={-45} textAnchor='end' />} />
                    <VictoryAxis dependentAxis domain={[minY, maxY]} tickFormat={percTickFormatter} tickCount={8} />
                    <VictoryLegend data={legend} x={0} y={0} orientation='horizontal' />
                    <VictoryScatter data={data} x='date' y='agres'
                        size={3}
                        symbol={({datum}) => datum.dist < 5 ? 'triangleUp' : (datum.dist > 10 ? 'square' : 'circle')}
                        style={{data: { fill: 'white', strokeWidth: 1, stroke: ({datum}) => datum.dist < 5 ? colors[0] : (datum.dist > 10 ? colors[2] : colors[1])}}}
                        labels={legendFormatter}
                        labelComponent={<VictoryTooltip />}
                    />
            </VictoryChart>
            </div>)
        }

        return <></>;
    }
}

class MultiPlotRec {
    name: string = '';
    date: number = 0;
    value: number = 0;
}

interface MultiChartProp {
    runners: string[];
}

interface MultiChartState {
    analytics: {};
    groupby: string;
    event: string;
}

export class MultiChart extends React.Component<MultiChartProp, MultiChartState> {
    constructor(props) {
        super(props);
        this.state = { analytics: {}, groupby: 'Q', event: 'ALL'};
        this.handleEvent = this.handleEvent.bind(this);
        this.handleGroupBy = this.handleGroupBy.bind(this);
    }

    componentDidMount() {
        this.loadAnalytics();
    }

    componentDidUpdate(prevProps) {
        if (!equalArrays(prevProps.runners, this.props.runners)) {
            this.loadAnalytics();
        }
    }

    loadAnalytics() {
        if (this.props.runners.length < 1)
            return;

        var event = this.state.event === 'ALL' ? '' : this.state.event; 
        var groupby = this.state.groupby === 'ALL' ? '' : this.state.groupby; 
        serverRequest("analytics?list=" + this.props.runners + "&groupby=" + groupby + "&event=" + event)
        .then(res => {
            this.setState( {analytics: res} );
        });
    }

    handleEvent(e) {
        this.setState({event: e.target.value}, () => { this.loadAnalytics();  });
    }

    handleGroupBy(e) {
        this.setState({groupby: e.target.value}, () => { this.loadAnalytics();  });
    }

    render() {
        var alldata = this.state.analytics;
        if (Object.keys(alldata).length === 0)
            return <></>;

        var style = {
            fontSize: '1.4rem',
        }
    
        var event_filter = <FormControl fullWidth margin='dense'>
                                <InputLabel sx={style}>Distance</InputLabel>
                                <Select value={this.state.event} label="Distance" onChange={this.handleEvent} sx={style}>
                                    <MenuItem key='ALL' value='ALL'>ALL</MenuItem>
                                    <MenuItem key='Short' value='short'>under 5K</MenuItem>
                                    <MenuItem key='Medium' value='medium'>5K to 10K</MenuItem>
                                    <MenuItem key='Long' value='long'>over 10K</MenuItem>
                                </Select>
                            </FormControl>

        var groupby_filter = <FormControl fullWidth margin='dense'>
                                <InputLabel sx={style}>View</InputLabel>
                                <Select value={this.state.groupby} label="Distance" onChange={this.handleGroupBy} sx={style}>
                                    <MenuItem key='Month' value='M'>Month</MenuItem>
                                    <MenuItem key='Quarter' value='Q'>Quarter</MenuItem>
                                    <MenuItem key='Year' value='Y'>Year</MenuItem>
                                </Select>
                            </FormControl>

        var results = {};
        var minY = 9999;
        var maxY = 0;
        var minX = new Date(2100, 1, 1).getTime();
        var maxX = 0;
        var foundPoints: boolean = false;
        var runner: Runner;
        for (const key in alldata) {
            var points = alldata[key];
            runner = getRunner(key);
            var name = formatShortName(runner);
            results[key] = [];
            for (var n=0; n<points.length; n++) {
                foundPoints = true;
                var pt = points[n];

                var dt: number = new Date(pt['date']).getTime();
                var value: number = pt['max'];  // TBD: choose between min, max, avg, med
                var res: MultiPlotRec = { name: name, date: dt, value: value };

                if (dt > maxX) maxX = dt;
                if (dt < minX) minX = dt;
                if (value > maxY) maxY = Math.ceil(value);
                if (value < minY) minY = Math.floor(value);
                results[key].push(res);
            }
        }

        var chart = <></>;
        if (foundPoints) {
            var lines: JSX.Element[] = [];
            var legend: Object[] = [];
            for (n=0; n<this.props.runners.length; n++) {
                var key = this.props.runners[n];
                runner = getRunner(key);

                lines.push(<VictoryLine
                            key={key}
                            data={results[key]}
                            x='date' y='value'
                            name={formatShortName(runner)}
                            style={{data: {stroke: colors[n%colors.length]} }}
                        />);

//                             labels={({ datum }) => dateGroupTickformatter(datum.date, this.state.groupby) + "\n" + datum.value.toFixed(1)+"%"}
//                             labelComponent={<VictoryTooltip />}

                legend.push({ name: formatShortName(runner), symbol: {fill: colors[n%colors.length] }})
            }

            chart = <VictoryChart
                            domain={{x: [minX, maxX], y: [minY, maxY]}}
                            containerComponent={<VictoryVoronoiContainer voronoiDimension='x' labels={({ datum }) => datum.name + ": " + datum.value.toFixed(1)+"%"} />}
                            theme={runTheme}
                        >
                            <VictoryAxis domain={[minX, maxX]} tickFormat={(t) => dateGroupTickformatter(t, this.state.groupby)} tickCount={10} tickLabelComponent={<VictoryLabel angle={-45} textAnchor='end' />} />
                            <VictoryAxis dependentAxis domain={[minY, maxY]} tickFormat={percTickFormatter} tickCount={8} />
                            <VictoryLegend data={legend} x={0} y={0} orientation='horizontal' />
                            {lines}
                        </VictoryChart>
        }

        return <div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {event_filter}
                    {groupby_filter}
                </div>
                {chart}
                </div>;
    }
}

function timeFormatter(tick, index, ticks) {
  return formatTime(tick, '5K');
}

function agFormatter(tick, index, ticks) {
  return tick.toFixed(0) + "%";
}

class AGPlotRec {
  ag: number = 0;
  time: number = 0;

  constructor(a, t) {
    this.ag = a;
    this.time = t;
  }
}

interface AGResChartProp {
  agres: number;
  tref: number;
}

export class AGResChart extends React.Component<AGResChartProp> {
  render() {
    var agres = this.props.agres;
    var tref = this.props.tref;
    var minX = Math.max(Math.floor(agres - 20.0), 30);
    var maxX = Math.min(Math.ceil(agres + 20.0), 101);
    var minY = agres / maxX * tref;
    var maxY = agres / minX * tref;

    var data: AGPlotRec[] = [];
    for (var ag=minX; ag<=maxX; ag++) {
        var pr: AGPlotRec = new AGPlotRec(ag, agres / ag * tref);
        data.push(pr)
    }

    var xTicks: number[] = [];
    var yTicks: number[] = [];
    for (ag=Math.round(minX/5)*5; ag<=maxX; ag+=5) {
      xTicks.push(ag)
      yTicks.push(Math.round(agres / ag * tref));
    }

    var dataVert: AGPlotRec[] = [new AGPlotRec(agres, tref), new AGPlotRec(agres, minY)]
    var dataHor: AGPlotRec[] = [new AGPlotRec(minX, tref), new AGPlotRec(agres, tref)]

    var chart = <VictoryChart>
                <VictoryAxis domain={[minX, maxX]} label={'AG Res'} tickValues={xTicks} tickFormat={agFormatter} tickLabelComponent={<VictoryLabel angle={-45} />} style={{ grid: {stroke: "grey"}, }} />
                <VictoryAxis dependentAxis domain={[minY, maxY]} tickValues={yTicks} tickFormat={timeFormatter} tickLabelComponent={<VictoryLabel angle={-45} />} style={{ grid: {stroke: "grey"}, }} />
                <VictoryLine data={data} x='ag' y='time' />
                <VictoryLine data={dataVert} x='ag' y='time' style={{data: {stroke: colors[0]} }} />
                <VictoryLine data={dataHor} x='ag' y='time'  style={{data: {stroke: colors[0]} }} />
            </VictoryChart>
    return chart;
  }
}

// VICTORY CHART THEME


// Colors

const colors = [
  red[700],
  indigo[700],
  teal[700],
  green[700],
  orange[700],
  purple[700],
];

const blueGrey50 = "#ECEFF1";
const blueGrey300 = "#90A4AE";
const blueGrey700 = "#455A64";
const grey900 = "#212121";

// Typography
const sansSerif = "'Helvetica Neue', 'Helvetica', sans-serif";
const letterSpacing = "normal";
const fontSize = 8;

// Layout
const padding = 8;
const baseProps = {
  width: 350,
  height: 350,
  padding: 40
};

// * Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding,
  fill: blueGrey700,
  stroke: "transparent",
  strokeWidth: 0
};

const centeredLabelStyles = Object.assign({ textAnchor: "middle" }, baseLabelStyles);

// Strokes
const strokeDasharray = "10, 5";
const strokeLinecap = "round";
const strokeLinejoin = "round";

// Put it all together...
const runTheme = {
  area: Object.assign(
    {
      style: {
        data: {
          fill: grey900
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  axis: Object.assign(
    {
      style: {
        axis: {
          fill: "transparent",
          stroke: blueGrey300,
          strokeWidth: 2,
          strokeLinecap,
          strokeLinejoin
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding,
          stroke: "transparent"
        }),
        grid: {
          fill: "none",
          stroke: blueGrey50,
          strokeDasharray,
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: "painted"
        },
        ticks: {
          fill: "transparent",
          size: 5,
          stroke: blueGrey300,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin
        },
        tickLabels: Object.assign({}, baseLabelStyles, {
          fill: blueGrey700
        })
      }
    },
    baseProps
  ),
  polarDependentAxis: Object.assign({
    style: {
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent"
      }
    }
  }),
  bar: Object.assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          padding,
          strokeWidth: 0
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  boxplot: Object.assign(
    {
      style: {
        max: { padding, stroke: blueGrey700, strokeWidth: 1 },
        maxLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        median: { padding, stroke: blueGrey700, strokeWidth: 1 },
        medianLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        min: { padding, stroke: blueGrey700, strokeWidth: 1 },
        minLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        q1: { padding, fill: blueGrey700 },
        q1Labels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        q3: { padding, fill: blueGrey700 },
        q3Labels: Object.assign({}, baseLabelStyles, { padding: 3 })
      },
      boxWidth: 20
    },
    baseProps
  ),
  candlestick: Object.assign(
    {
      style: {
        data: {
          stroke: blueGrey700
        },
        labels: Object.assign({}, baseLabelStyles, { padding: 5 })
      },
      candleColors: {
        positive: "#ffffff",
        negative: blueGrey700
      }
    },
    baseProps
  ),
  chart: baseProps,
  errorbar: Object.assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: "transparent",
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  group: Object.assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  histogram: Object.assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          stroke: grey900,
          strokeWidth: 2
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "circle"
      },
      labels: baseLabelStyles,
      title: Object.assign({}, baseLabelStyles, { padding: 5 })
    }
  },
  line: Object.assign(
    {
      style: {
        data: {
          fill: "transparent",
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  pie: Object.assign(
    {
      colorScale: colors,
      style: {
        data: {
          padding,
          stroke: blueGrey50,
          strokeWidth: 1
        },
        labels: Object.assign({}, baseLabelStyles, { padding: 20 })
      }
    },
    baseProps
  ),
  scatter: Object.assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          opacity: 1,
          stroke: "transparent",
          strokeWidth: 0
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  stack: Object.assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  tooltip: {
    style: Object.assign({}, baseLabelStyles, { padding: 0, pointerEvents: "none" }),
    flyoutStyle: {
      stroke: grey900,
      strokeWidth: 1,
      fill: "#f0f0f0",
      pointerEvents: "none"
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10
  },
  voronoi: Object.assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: "transparent",
          strokeWidth: 0
        },
        labels: Object.assign({}, baseLabelStyles, { padding: 5, pointerEvents: "none" }),
        flyout: {
          stroke: grey900,
          strokeWidth: 1,
          fill: "#f0f0f0",
          pointerEvents: "none"
        }
      }
    },
    baseProps
  )
};
