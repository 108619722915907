import React from 'react';
import { Badge, Toolbar, AppBar } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import EmojiEvents from '@mui/icons-material/EmojiEventsOutlined';
import StarIcon from '@mui/icons-material/StarOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';

import { useRef, useState, useLayoutEffect } from 'react';

import { useMediaQuery } from 'react-responsive'
import { PLATFORM } from './Util';

interface TBarProps { variant? };
export function TBar(props: React.PropsWithChildren<TBarProps>) {
    var tbar_style = props.variant === "secondary" ? {} : { backgroundColor: 'var(--secondary-color)' };

    return <Toolbar variant="dense" sx={tbar_style}>
        {props.children}
    </Toolbar>
}

interface BadgeNumProps {content: number, compact: boolean };
export function BadgeNum(props: React.PropsWithChildren<BadgeNumProps>) {
    var margin = props.compact ? 0 : 3;
    return <Badge badgeContent={props.content}
            sx={{ mr: {margin}, "& .MuiBadge-badge": { fontSize: '1.2rem', height: 12, color: 'var(--table-text-color)' } }} >
    {props.children}
    </Badge>
}

interface TopAwardProps {content: number, color: string, compact: boolean, isgold: boolean}
export const TopAward: React.FunctionComponent<TopAwardProps> = props =>
    <BadgeNum children={<EmojiEvents width='16px' height='16px' sx={{color: props.isgold ? 'var(--text-highlight)' : 'var(--text-highlight-2)'}} />} content={props.content} compact={props.compact} />

interface PBAwardProps {content: number, color: string, compact: boolean, isgold: boolean}
export const PBAward: React.FunctionComponent<PBAwardProps> = props =>
       <BadgeNum children={<StarIcon width='16px' height='16px' sx={{color: props.isgold ? 'var(--text-highlight)' : 'var(--text-highlight-2)'}} />} content={props.content} compact={props.compact} />


export function formatAwards(numtop10, numytop10, numpb, numsb, compact) {
    var top10 = (numtop10 > 0) ? <span>{numtop10}<EmojiEvents width='16px' height='16px' sx={{color: 'var(--text-highlight)'}} /></span> : null;
    var ytop10 = (numytop10 > 0) ? <span>{numytop10}<EmojiEvents width='16px' height='16px' sx={{color: 'var(--text-highlight-2)'}} /></span> : null;
    var npb = (numpb > 0) ? <span>{numpb}<StarIcon width='16px' height='16px' sx={{color: 'var(--text-highlight)'}} /></span> : null;
    var nsb = (numsb > 0) ? <span>{numsb}<StarIcon width='16px' height='16px' sx={{color: 'var(--text-highlight-2)'}} /></span> : null;

    return <span>
        {top10} {ytop10} {npb} {nsb}
        </span>;
}

export const Input = (props) => {
    return (  
        <div className="form-group">
            <label className="form-label">{props.title}</label>
            <input
                className="form-input"
                id={props.name}
                name={props.name}
                type={props.inputtype}
                value={props.value}
                onChange={props.handleChange}
                placeholder={props.placeholder} 
                size={props.size}
                step={props.step}
                autoComplete="new-password"
                />
        </div>
)}

export const Option = (props) => {
    return (  
        <div className="form-group">
            <label className="form-label">{props.title}</label>
            <select
                className="form-input"
                id={props.name}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
                size={props.size}
                >
            {props.children}
            </select>
        </div>
)}

export const PlainButton = (props) => {
	return(
        <button 
            style= {props.style} 
            className = "form-button"
            onClick= {props.action} > 
            {props.title} 
        </button>
)}

// import { height } from '@mui/material/node_modules/@mui/system';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <div style={{paddingTop: '10px'}}>
            {children}
            </div>
        )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function tabProps(index) {
    return {
        id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, 
    };
}

export function ABar(props) {
    const nav = useRef<any>();
    const [navHeight, setNavHeight] = useState(0);
    const [isLargeScreen, setLargeScreen] = useState(false);
  
    useLayoutEffect(() => {
      nav.current && setNavHeight(nav.current.clientHeight);
    }, [isLargeScreen]);
  
    const checkisLarge = () => (window.innerWidth >= 600 && window.innerHeight >= 600 ? true : false);

    if (typeof window !== 'undefined') {
      window.onresize = () => isLargeScreen !== checkisLarge() && setLargeScreen(checkisLarge);
    } 
  
    if (props.position === "top") {
        var topPadding = <div style={{ paddingBottom: `${navHeight}px`}} />
        let styles: any = { background: 'var(--secondary-color)', bottom: 'auto' }
        if (PLATFORM === 'ios') {
            topPadding = <div style={{ paddingBottom: 'calc(env(safe-area-inset-top) + 80px'}} />
            styles = { paddingTop: '30px', ...styles };
        }
    
        return (
            <>
            <AppBar ref={nav} position="fixed" sx={styles}>
                    {props.children}
                </AppBar>
                {topPadding}
            </>
            )
    } else {
        return (
            <>
                <AppBar ref={nav} color="primary" position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                    {props.children}
                </AppBar>
                <div style={{ paddingTop: `${navHeight}px`}} />
            </>
            )
    }
  }


export function Title(props) {
    return <span className='largetext' style={{marginLeft: '10px'}}>
        {props.text}
        </span>
}

export function ExpandableSection(props) {
    return <Accordion sx={{ backgroundColor: 'var(--group-color)' }} >
                <AccordionSummary expandIcon={<ExpandMoreIcon fontSize='large' /> } >
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', width: '100%' }} >
                        <div style={{ flexShrink: 0, flexGrow: 1, flexBasis: 'var(--field-width)' }}>
                            <div style={{fontSize: '1.6rem', fontWeight: 'bold', marginLeft: '10px'}}>{props.title}</div>
                        </div>
                        <div style={{ flexShrink: 0, flexGrow: 0, flexBasis: 'var(--field-width)' }}>
                            {props.details}
                        </div>
                    </div>
                </AccordionSummary> 
                <AccordionDetails sx={{ p: 0, backgroundColor: 'var(--paper-color)' }} >
                    {props.children}
                </AccordionDetails>
            </Accordion>
}

export enum SCREEN {
    SMALL=1,
    MEDIUM=2,
    LARGE=3
}

export const Setup = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  return <div>
    <h1>Device Test!</h1>
    {isDesktopOrLaptop && <p>You are a desktop or laptop</p>}
    {isBigScreen && <p>You  have a huge screen</p>}
    {isTabletOrMobile && <p>You are a tablet or mobile phone</p>}
    <p>Your are in {isPortrait ? 'portrait' : 'landscape'} orientation</p>
    {isRetina && <p>You are retina</p>}
  </div>
}

