import React from 'react';
import { Box, IconButton } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FlagIcon from '@mui/icons-material/FlagOutlined';

import '../Running.css';
import { Filter, FilterPrefs, FilterDrawer } from './Filter';
import { TBar, ABar, ExpandableSection } from './Widgets';
import { addCell, formatTime, formatShortName, ALL_EVENTS, EVENT_TYPES, IS_ROAD,
            Finisher, Runner, getRunner, KEY_EVENTS, sortKeys, serverRequest, serverRequestBlob, ISNATIVE } from './Util';
import { getLeaderCard } from './Races';
import { Finishers } from './Runner';
import { GlobalContext } from '../context/GlobalContextProvider';
import MenuIcon from '@mui/icons-material/Menu';
import { PageHeader } from './PageHeader/PageHeader';


interface PBMultiState {
}

interface PBMultiProps {
    pblist: {};
    runners: string[];
}

export class PBMulti extends React.Component<PBMultiProps, PBMultiState> {
    componentDidUpdate(prevProps) {
        if (prevProps.pblist !== this.props.pblist)
            this.setState( { selRaceID: 0 } );
    }
    
    render_event(cells, event, elem) {
        if (elem !== undefined) {
            var perf = elem[0]

            cells.push(<td className='pbcol'>
                        <b>{formatTime(perf, event)}</b>
                        </td>);
        } else
            cells.push(addCell(''));
    }

    render() {
        // Finds all events and all years and builds a direct access dict
        var pbdict: {} = {};
        var events: string[] = [];
        for (var key in this.props.pblist) {
            var pb = this.props.pblist[key]
            var rid = pb['ID']
            var event = pb['EKey']  // we consider all suitable etypes, in standard distances
            pbdict[rid+","+event] = [pb['Time'], pb['MeetingID'],rid]
            if (events.indexOf(event) < 0)
            events.push(event);
        }

        events.sort(PBTable.sortByEvent);

        var rows: JSX.Element[] = [];
        for (event of events) {
            var cells: JSX.Element[] = [];     
            var ind = ALL_EVENTS.indexOf(event);
            var bgcol = ind >= 0 && EVENT_TYPES[ind] === IS_ROAD ? 'var(--table-header-color)' : 'var(--table-alt-header-color)'
            cells.push(<th className='sticky-col first-col' style={{backgroundColor: bgcol}}>{event}</th>);

            for (rid of this.props.runners) {
                var elem = pbdict[rid+","+event]
                this.render_event(cells, event, elem);
            }
            rows.push(<tr>{cells}</tr>);
        }

        // Header
        var head = <tr><th></th>{this.props.runners.map((key, index) => {
                var runner = getRunner(key);
                return <th>{formatShortName(runner)}</th> })
            }</tr>
        var table = <div className='wrapper'>
                        <table className='bicolortable'><thead>{head}</thead><tbody className='link'>{rows}</tbody></table>
                    </div>
        
        return <div>{table}</div>
    }
}

interface PBTableState {
    selRaceID;
    recRunnerID: string;
    event: string;
    year: number;
}

interface PBTableProps {
    pblist: {};
    showRunner: boolean;
    selRunnerID: string;
    mainOnly: boolean;
    age_group: string;
    gender: string;
}

export class PBTable extends React.Component<PBTableProps, PBTableState> {
    constructor(props) {
        super(props);
        this.state = { selRaceID: 0, recRunnerID: this.props.selRunnerID, event: '', year: 0 }
        this.onSelectRace = this.onSelectRace.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pblist !== this.props.pblist)
            this.setState( { selRaceID: 0 } );
    }
    
    // TBD: for club records, show leaderboard, for runner's records, show meeting finishers and list of best runner's performances
    onSelectRace = (e) => {
        const mID = Number(e.currentTarget.getAttribute('meeting-key'));
        const event = e.currentTarget.getAttribute('event-key');
        const year = Number(e.currentTarget.getAttribute('year-key'));
        // console.log(event, year);
        if (mID === 0) {
            const deselect = this.state.event === event && this.state.year === year;
            if (deselect)
                this.setState( { selRaceID: 0, recRunnerID: this.props.selRunnerID, event: '', year: 0} );  // TBD: non serve settare recRunnerID?
            else
                this.setState( { selRaceID: 0, recRunnerID: this.props.selRunnerID, event: event, year: year} );  // TBD: non serve settare recRunnerID?
        } else {
            const rID = e.currentTarget.getAttribute('runner-key');
            this.setState( { selRaceID: this.state.selRaceID === mID ? 0 : mID, recRunnerID: rID, event: event, year: year} );
        }
    }

    render_event(cells, event, year, elem, eventrec) {
        if (elem !== undefined) {
            var perf = elem[0]
            var meeting = elem[1]
            var runner = this.props.showRunner ? getRunner(elem[2]) : undefined;
            var agres = elem[3] > 0 ? elem[3].toFixed(1)+"%" : '';

            var sbstyle = (perf === eventrec) ? {'color': 'red'} : {};
            if (meeting === this.state.selRaceID || (event === this.state.event && year === this.state.year)) {
                sbstyle['backgroundColor'] = 'yellow';  // TBD: find better way to select cell
            }

            if (runner !== undefined)
                cells.push(<td onClick={this.onSelectRace} runner-key={runner.ID} meeting-key={0} event-key={event} year-key={year} style={sbstyle} className='pbcol'>
                            <b>{formatTime(perf, event)}</b><br/>{formatShortName(runner)}
                            </td>);
            else
                cells.push(<td onClick={this.onSelectRace} runner-key={this.state.recRunnerID} meeting-key={meeting} event-key={event} year-key={year} style={sbstyle} className='pbcol'>
                            <b>{formatTime(perf, event)}</b><br/>{agres}
                            </td>);
        } else
            cells.push(addCell(''));
    }

    // order so we have Road first, Track then, and longer events first)
    static sortByEvent(a, b) {
        var pa = ALL_EVENTS.indexOf(a);
        var pb = ALL_EVENTS.indexOf(b);
        if (pa < 0 || pb < 0) return -1

        if (EVENT_TYPES[pa] !== EVENT_TYPES[pb])
            return EVENT_TYPES[pa] === IS_ROAD ? -1 : 1;
        else
            return pa < pb ? 1 : -1;
    }
    
    render() {
        // Finds all events and all years and builds a direct access dict
        var pbdict: {} = {};
        var eventrec: {} = {};
        var years: string[] = [];
        // console.log(this.props.pblist);
        for (var key in this.props.pblist) {
            var pb = this.props.pblist[key]
            var year = pb['Year']
            var event = pb['EKey']  // we consider all suitable etypes, in standard distances

            // Calculates all-time records
            if (eventrec[event] === undefined || eventrec[event][0] > pb['Time'])
                eventrec[event] = [pb['Time'], pb['MeetingID'], pb['ID'], pb['AGRes']]

            if (this.props.showRunner && parseInt(year) < 2000)  // skip old records at club level (they will still show in all-time row)
                continue;

            if (years.indexOf(year) < 0)
                years.push(year)

            pbdict[year+","+event] = [pb['Time'], pb['MeetingID'], pb['ID'], pb['AGRes']]
        }

        // Sorts events and years
        var events: string[] = Object.keys(eventrec);
        events.sort(PBTable.sortByEvent);
        years.sort().reverse();

        var rows: JSX.Element[] = [];
        for (event of events) {
            if (this.props.mainOnly && KEY_EVENTS.indexOf(event) < 0)
                continue;

            var cells: JSX.Element[] = [];     
            var ind = ALL_EVENTS.indexOf(event);
            var bgcol = ind >= 0 && EVENT_TYPES[ind] === IS_ROAD ? 'var(--table-header-color)' : 'var(--table-alt-header-color)'
            cells.push(<th className='sticky-col first-col' style={{backgroundColor: bgcol}}>{event}</th>);
            // All-time Records
            var elem = eventrec[event]
            this.render_event(cells, event, 0, elem, elem[0])

            for (year of years) {
                if (this.props.showRunner && parseInt(year) < 2000)  // do not show very old records at club level (they might show in the "ALL" row)
                    continue;

                elem = pbdict[year+","+event]
                this.render_event(cells, event, year, elem, eventrec[event][0]);
            }
            rows.push(<tr>{cells}</tr>);
        }

        // Shows selected race or leaderboard
        var selDetail = <></>
        var maxH = '100vh';
        var period = this.state.year === 0 ? "All-time" : this.state.year;
        if (this.state.selRaceID !== 0) {
            var filter: Filter = new Filter(this.state.year, 0, this.state.event, '', '', false, '');
            //   <MeetingFinishers raceID={this.state.selRaceID} runnerID={this.state.recRunnerID} showDescr={true} />
            selDetail = <div style={{'marginTop': '5px'}}>
                <FixedLeaderboard rID={this.state.recRunnerID} filter={filter} title={period + ' | ' + this.state.event} start_open={true} variant='runner' />
                </div>
            maxH = '40vh';
        } else if (this.props.showRunner && this.state.event !== '') {
            filter = new Filter(this.state.year, 0, this.state.event, this.props.gender, this.props.age_group, false, '');
            var g_ag: string = this.props.gender === "M" ? "Men" : "Women";
            if (this.props.age_group !== '')
                g_ag += " | " + this.props.age_group;
            selDetail = <div style={{'marginTop': '5px'}}>
                    <FixedLeaderboard filter={filter} rID='' title={period + ' | ' + this.state.event + " | " + g_ag} start_open={true} variant='' />
                </div>            
            maxH = '40vh';
        }

        // Header
        var head = <tr><th></th><th>{(this.props.showRunner ? "ALL": "PB")}</th>{years.map((key, index) => { return <th>{key}</th> })}</tr>
        var table = <div className='wrapper' style={{'maxHeight': maxH}}>
                        <table className='bicolortable'><thead>{head}</thead><tbody className='link'>{rows}</tbody></table>
                    </div>
        
        return <div style={{padding: '16px'}}>{table}{selDetail}</div>
    }
}

interface LeaderboardProps { 
    finishers: {};
    variant: string;
}

interface LeaderboardState {
    selRaceID: number;
    selRunnerID: string;
}

export class Leaderboard extends  React.Component<LeaderboardProps, LeaderboardState> {
    public static defaultProps = { variant: '' };

    constructor(props) {
        super(props);

        this.state = {
            selRaceID: 0,
            selRunnerID: '',
        }
        this.onSelectRace = this.onSelectRace.bind(this);
    }

    onSelectRace = (e) => {
        const ID = Number(e.currentTarget.getAttribute('data-key'));
        const rID = e.currentTarget.getAttribute('runner-key');
        this.setState( { selRaceID: ID === this.state.selRaceID ? 0 : ID, selRunnerID: rID === this.state.selRunnerID ? 0 : rID } );
    }

    render() {
        var keys = Object.keys(this.props.finishers);

        if (keys.length > 0) {
            var cards: JSX.Element[] = [];
            // console.log(this.props.finishers);

            // A full leaderboard is a set of performances from many events, and we sort them by date
            // otherwise for a single event it will come already sorted by Time
            if (this.props.variant === 'full')
                keys = sortKeys(this.props.finishers, 'Date', null, false);
            else if (this.props.variant === 'runner')
                keys = sortKeys(this.props.finishers, 'Time', null, true);

            var pos = 1;
            for (var key in keys) {
                var rkey = keys[key];
                var fin: Finisher = this.props.finishers[rkey];
                var runner: Runner = getRunner(fin.ID);
                // console.log(pos + ": " + fin.Time);
                // console.log(runner) // NOTE this is _sometimes_ returning undefined and breaking the home page
                var isOpen = (this.props.variant === 'runner') ? this.state.selRaceID === fin.MeetingID : this.state.selRunnerID === runner.ID;
                cards.push(getLeaderCard(runner, fin, pos, this.props.variant, isOpen, this.onSelectRace));
                pos++;
            }

            /*
            if (rows.length > 0)
                tables.push(<div className='cardset'>{rows}</div>);
                // tables.push(<div className='plaintable'><table><tbody className='link'>{rows}</tbody></table></div>);
                */
            return <div className='cardset'>{cards}</div>;
        }
        return <h3>No results available for this selection</h3>       
    } 
}

interface FixedLeaderboardProps { 
    filter: Filter;
    rID: string;
    variant: string;
    start_open: boolean;
    title: string;
}

interface FixedLeaderboardState {
    ranking: {};
    numRunners: number;
    numRaces: number;
}

export class FixedLeaderboard extends  React.Component<FixedLeaderboardProps, FixedLeaderboardState> {
    public static defaultProps = { variant: '' };

    constructor(props) {
        super(props);
        this.state = {
            ranking: {},
            numRunners: 0,
            numRaces: 0
        }
        this.loadRanking = this.loadRanking.bind(this);
        this.onPrint = this.onPrint.bind(this);
    }

    componentDidMount() {
        this.loadRanking(this.props.filter, true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter) {
            this.loadRanking(this.props.filter, true);
        }
    }

    recalc() {
        var meetings = {};
        var reskeys = Object.keys(this.state.ranking);
        for (var n=0; n<reskeys.length; n++) {
            var key = reskeys[n];
            var fin: Finisher = this.state.ranking[key];
            if (meetings[fin.MeetingID] === undefined)
                meetings[fin.MeetingID] = 1;
        }
        this.setState( { numRunners: reskeys.length, numRaces: Object.keys(meetings).length } );
    }

    loadRanking(filter: Filter, requery) {
        var params = Filter.get_params(filter);

        if (this.props.rID === '') {
            serverRequest("customranking?" + params)
            .then(res => {
                this.setState({ranking: res }, () => {
                    this.recalc();
                });
            });
        } else {
            var filterstr = "ID=" + this.props.rID + "&meeting=" + filter.meeting + "&year=" + filter.year + "&month=0&event=" + filter.event + "&achievements=0";
            serverRequest("race?" + filterstr)
            .then(res => {
                this.setState({ranking: res }, () => {
                    this.recalc();
                });
            });
        }
    }
    
    onPrint() {
        // TBD: single-event multi-meeting (e.g. all-time or year records) leaderboards require a different format
        var params = Filter.get_params(this.props.filter);
        serverRequestBlob("printranking?" + params)
        .then(blob => {
            var url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            const fname = "Leaderboard " + this.props.filter.event + ".pdf";
            link.href = url;
            link.setAttribute('download', fname);
            link.click();
        });
    }

    render() {
        var download = <></>
        if (ISNATIVE && Object.keys(this.state.ranking).length > 0)
                download = <IconButton onClick={this.onPrint} >
                    <FileDownloadOutlinedIcon fontSize='large'/>
                </IconButton>

        var leaderboard = <Leaderboard finishers={this.state.ranking} variant={this.props.variant} />;
        if (this.props.start_open) {
            return <div>
                <div className='largetext'>{this.props.title}{download}</div>
                {leaderboard}
            </div>
        } else {
            var details;
            var keys = Object.keys(this.state.ranking);
            if (keys.length > 0) {
                // var rkey = keys[0];
                // var fin: Finisher = this.state.ranking[rkey];
                // var header = fin.Event + " " + formatDateString(fin.Date)
                details = <div style={{color: 'var(--primary-color)'}}>
                            {this.state.numRaces}<FlagIcon width='16px' height='16px' />
                            {this.state.numRunners}<DirectionsRunIcon width='16px' height='16px' />
                        </div>;
            } else
                details = <></>
            return <ExpandableSection title={this.props.title} details={details} >
                    {download}
                    {leaderboard}
                </ExpandableSection>
        }
    }
}

interface CustomLeaderboardProps { 
    start_filter: Filter;
    start_open: boolean;
    title: string;
}

interface CustomLeaderboardState {
    filter: Filter;
    filterlabel: string;
    fileDownloadUrl;
}

export class CustomLeaderboard extends React.Component<CustomLeaderboardProps, CustomLeaderboardState> {
    constructor(props) {
        super(props);
        this.state = {
            filter: this.props.start_filter,
            filterlabel: '',
            fileDownloadUrl: null
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(filter: Filter, requery: boolean) {
        this.setState( { filter: Object.assign({}, filter) });
    }

    static filter_prefs: FilterPrefs = {year: false, month: false,
        event: true, all_event: false, mainOnly: false,
        gender: true, all_gender: true, age_group: true, all_age_group: true, meeting: false };

    render() {
        var tables = <FixedLeaderboard filter={this.state.filter} rID='' start_open={this.props.start_open} title={this.props.title} variant='simple' />
        return <div>
                {/* {appbar} */}
                <PageHeader title='Custom Leaderboard' filter={<FilterDrawer onChange={this.onChange} prefs={CustomLeaderboard.filter_prefs} start_filter={this.props.start_filter} display_filter={true} />} filterLabel={''}></PageHeader>
                <div>{tables}</div>
                </div>;
    }
}

interface RankingsProps { }

interface RankingsState {
    ranking: {};
    event: string;
    selRaceID: number;
    selRunnerID: string;
    records: {};
    mainOnly: boolean;
    age_group: string,
    gender: string
}

export class Rankings extends  React.Component<RankingsProps, RankingsState> {

  static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            ranking: {},
            records: {},
            event: '',
            selRaceID: 0,
            selRunnerID: '',
            mainOnly: true,
            age_group: '',
            gender: ''
        }
        this.loadRanking = this.loadRanking.bind(this);
        this.onSelectRace = this.onSelectRace.bind(this);
    }

    componentDidMount() {
        this.loadRanking(new Filter(0, 0, this.state.event, 'W', '', this.state.mainOnly, ''), true);
    }

    onSelectRace = (e) => {
        const ID = Number(e.currentTarget.getAttribute('data-key'));
        const rID = e.currentTarget.getAttribute('runner-key');
        this.setState( { selRaceID: ID === this.state.selRaceID ? 0 : ID, selRunnerID: rID === this.state.selRunnerID ? 0 : rID } );
    }
    
    loadRanking(filter: Filter, requery) {
        if (requery) {
            if (filter.event !== '') {
                serverRequest("ranking?year=" + filter.year + "&event=" + filter.event + "&gender=" + filter.gender + "&age_group=" + filter.age_group)
                .then(res => {
                    this.setState({ranking: res, selRaceID: 0, event: filter.event, mainOnly: filter.mainOnly, gender: filter.gender, age_group: filter.age_group});
                });
            } else {
                serverRequest("records?gender=" + filter.gender + "&age_group=" + filter.age_group)
                .then(res => {
                    this.setState({records: res, selRaceID: 0, event: filter.event, mainOnly: filter.mainOnly, gender: filter.gender, age_group: filter.age_group});
                });
            }
        } else {
            this.setState({mainOnly: filter.mainOnly});
        }
    }

    static filter_prefs: FilterPrefs = {year: true, month: false,
        event: true, all_event: true, mainOnly: true,
        gender: true, all_gender: false, age_group: true, all_age_group: true, meeting: false };

    openMenu(context) {
        context.setMenuOpen(!context.menuOpen);
    }

    render() {
        var filter = <FilterDrawer onChange={this.loadRanking} prefs={Rankings.filter_prefs} start_filter={new Filter(0, 0, '', 'W', '', this.state.mainOnly, '')} display_filter={true} />  
        
        var appbar = <div>
                <ABar position="top" >
                <TBar>
              <IconButton sx={{ color: 'var(--paper-color)'}} onClick={() => this.openMenu(this.context)}>

                <MenuIcon fontSize="large"></MenuIcon>
              </IconButton>
                    <div className='largetext' >Records</div>
                    <Box sx={{ flexGrow: 1}} />
                    {filter}
                </TBar>
                </ABar>
            </div>

        var tables = (this.state.event === '') ?
                    <PBTable pblist={this.state.records} showRunner={true} selRunnerID={''} mainOnly={this.state.mainOnly} age_group={this.state.age_group} gender={this.state.gender} /> :
                    <Leaderboard finishers={this.state.ranking} />;

        return <div>
            {appbar}
            <div>{tables}</div>
            </div>;
    }
}

interface ProgressionsProps { }

interface ProgressionsState {
    selRaceID: number;
    records: {};
    mainOnly: boolean;
    event: string;
    age_group: string,
    gender: string
}

export class Progressions extends  React.Component<ProgressionsProps, ProgressionsState> {
    constructor(props) {
        super(props);

        this.state = {
            records: {},
            selRaceID: 0,
            mainOnly: true,
            age_group: '',
            gender: '',
            event: ''
        }
        this.loadProgr = this.loadProgr.bind(this);
    }

    componentDidMount() {
        this.loadProgr(new Filter(0, 0, '', 'W', '', this.state.mainOnly, ''), true);
    }

    loadProgr(filter: Filter, requery) {
        if (requery) {
                serverRequest("recordprogr?gender=" + filter.gender + "&age_group=" + filter.age_group + "&event=" + filter.event)
                .then(res => {
                    this.setState({records: res, selRaceID: 0, mainOnly: filter.mainOnly, gender: filter.gender, age_group: filter.age_group, event: filter.event});
                });
        } else {
            this.setState({mainOnly: filter.mainOnly});
        }
    }

    static filter_prefs: FilterPrefs = {year: false, month: false,
        event: true, all_event: true, mainOnly: true,
        gender: true, all_gender: false, age_group: true, all_age_group: true, meeting: false };

    render() {
        var tables = <Finishers finishers={this.state.records} variant="date" />;

        return <div>
          <PageHeader title='Record Progressions' filter={<FilterDrawer onChange={this.loadProgr} prefs={Progressions.filter_prefs} start_filter={new Filter(0, 0, '', 'W', '', this.state.mainOnly, '')} display_filter={true} />} filterLabel={''}></PageHeader>
            <div>{tables}</div>
            </div>;
    }
}
