import React from "react"
import { Box, List, ListItem, SwipeableDrawer } from '@mui/material';
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { ISNATIVE } from "../Util";
import { SideMenuButton } from "./SideMenuButtons/SideMenuButton";

import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import InfoIcon from '@mui/icons-material/Info';
import GroupsIcon from '@mui/icons-material/Groups';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import FastForwardIcon from '@mui/icons-material/FastForward';
import HistoryIcon from '@mui/icons-material/History';
import DirectionsRun from '@mui/icons-material/DirectionsRun';
import HomeIcon from '@mui/icons-material/Home';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

export const SideMenu = () => {
  

  const {menuOpen, setMenuOpen} = useGlobalContext();

  const toggleDrawer = (open: boolean) => {

    if (open) {
      setMenuOpen(true);
    }

    if (!open) {
      setMenuOpen(false);
    }

  }

  const optionList = () => (
    <Box
    sx={{color: "var(--paper-color)", paddingTop: ISNATIVE ? '30px' : '0'}}
    >
      <List>
        <ListItem>
          <SideMenuButton title='Home' route='home' icon={<HomeIcon fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Runners' route='runners' icon={<DirectionsRun fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Results' route='races' icon={<HistoryIcon fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Head To Head' route='headtohead' icon={<FastForwardIcon fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Records' route='leaderboard' icon={<EmojiEvents fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Parkrun Records' route='parkrun' icon={<EmojiEvents fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Custom Leaderboard' route='customleaderboard' icon={<LeaderboardIcon fontSize="large"></LeaderboardIcon>}></SideMenuButton>
        </ListItem>    
        <ListItem>
          <SideMenuButton title='Record Progressions' route='progressions' icon={<WorkspacePremiumIcon fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Club Stats' route='stats' icon={<GroupsIcon fontSize="large"/>}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='Club Championships' route='champs' icon={<MilitaryTechIcon fontSize="large" />}></SideMenuButton>
        </ListItem>
        <ListItem>
          <SideMenuButton title='About' route='about' icon={<InfoIcon fontSize="large" />}></SideMenuButton>
        </ListItem>
      </List>
    </Box>
  )


  return (
    <>
      <SwipeableDrawer
        PaperProps={{ 
          sx: {
        backgroundColor: '#1976d2'
          }
        }}
        anchor={"left"}
        open={menuOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}>
        {optionList()}
      </SwipeableDrawer>
    </>
  )

}